export const getCroppedImage = (imageSrc, croppedAreaPixels, rotation = 0) => {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.src = imageSrc
    image.onload = () => {
      const canvas = document.createElement("canvas")
      const ctx = canvas.getContext("2d")

      const radians = (rotation * Math.PI) / 180

      const tempCanvas = document.createElement("canvas")
      const tempCtx = tempCanvas.getContext("2d")

      const boundingBoxWidth = Math.abs(Math.cos(radians) * image.width) + Math.abs(Math.sin(radians) * image.height)
      const boundingBoxHeight = Math.abs(Math.sin(radians) * image.width) + Math.abs(Math.cos(radians) * image.height)

      tempCanvas.width = boundingBoxWidth
      tempCanvas.height = boundingBoxHeight

      tempCtx.translate(boundingBoxWidth / 2, boundingBoxHeight / 2)
      tempCtx.rotate(radians)
      tempCtx.drawImage(image, -image.width / 2, -image.height / 2)

      canvas.width = croppedAreaPixels.width
      canvas.height = croppedAreaPixels.height

      ctx.drawImage(tempCanvas, croppedAreaPixels.x, croppedAreaPixels.y, croppedAreaPixels.width, croppedAreaPixels.height, 0, 0, croppedAreaPixels.width, croppedAreaPixels.height)

      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error("Canvas is empty"))
          return
        }
        const file = new File([blob], "cropped_image.jpeg", {
          type: "image/jpeg",
        })
        resolve(file)
      }, "image/jpeg")
    }
    image.onerror = reject
  })
}
