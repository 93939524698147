import { useDispatch } from "react-redux"
import useBaseService from "./useBaseService"
import { setUser, setToken, setCredits, setLogout } from "../redux/reducers/authReducer"
import { resetState } from "../redux/reducers/influencerReducer"
import notify from "../components/Common/Toast"

const useAuthService = () => {
  const dispatch = useDispatch()
  const axiosRequest = useBaseService().axiosRequest()

  const register = async ({ name, companyName, email, location, role, password, confirmPassword, subscription }, recapthaToken, referral) => {
    try {
      const response = await axiosRequest.post("users/register", {
        name,
        company_name: companyName,
        email,
        location,
        role,
        password,
        password_confirmation: confirmPassword,
        subscription,
        recaptcha_token: recapthaToken,
        rewardful_referral: referral,
      })
      dispatch(setUser(response.data))
      return response.data
    } catch (error) {
      notify.error(error.response.data.error)
      return false
    }
  }

  const login = async ({ email, password }, recapthaToken) => {
    try {
      const response = await axiosRequest.post("users/login", {
        email,
        password,
        recaptcha_token: recapthaToken,
      })
      if (response.data.role === "brand") {
        dispatch(setUser(response.data))
        dispatch(setCredits(response.data.credits))
        dispatch(setToken(response.headers["authorization"]))
      }
      return response.data
    } catch (error) {
      notify.error(error.response.data.error)
      return false
    }
  }

  const forgotPassword = async ({ email }) => {
    try {
      await axiosRequest.post("users/forgot_password", {
        email,
      })
      notify.success("Reset password instructions sent successfully")
      return true
    } catch (error) {
      notify.error(error.response.data.error)
      return false
    }
  }

  const resetPassword = async ({ password, confirmPassword, resetPasswordToken }) => {
    try {
      await axiosRequest.put("users/reset_password", {
        password,
        password_confirmation: confirmPassword,
        reset_password_token: resetPasswordToken,
      })
      notify.success("Password reset successfully")
      return true
    } catch (error) {
      notify.error(error.response.data.error)
      return false
    }
  }

  const confirmation = async (confirmationToken) => {
    try {
      const response = await axiosRequest.get(`users/account_confirmation?confirmation_token=${confirmationToken}`)
      if (response.data.role === "brand") {
        dispatch(setUser(response.data))
        dispatch(setCredits(response.data.credits))
        dispatch(setToken(response.headers["authorization"]))
      }
      return response.data
    } catch (error) {
      notify.error(error.response.data.error)
      return false
    }
  }

  const socialAuthentication = async ({ token, provider }) => {
    try {
      const response = await axiosRequest.post("users/social_auth/callback", {
        token,
        provider,
      })
      dispatch(setUser(response.data))
      dispatch(setCredits(response.data.credits))
      dispatch(setToken(response.headers["authorization"]))
      return response.data
    } catch (error) {
      notify.error(error.response.data.error)
      return false
    }
  }

  const setRole = async ({ name, companyName, role }) => {
    try {
      const response = await axiosRequest.put("users/update", {
        name,
        company_name: companyName,
        role,
      })

      if (response.data.role === "brand") {
        dispatch(setUser(response.data))
      } else {
        dispatch(setToken(null))
      }

      return response.data
    } catch (error) {
      notify.error(error.response.data.error)
      return false
    }
  }

  const resendConfirmation = async (email) => {
    try {
      await axiosRequest.post("users/resend_confirmation", {
        email,
      })
      notify.success("Account confirmation email re-sent")
      return true
    } catch (error) {
      notify.error(error.response.data.error)
      return false
    }
  }

  const logout = async () => {
    try {
      await axiosRequest.delete("users/logout")
      dispatch(setLogout())
      dispatch(resetState())
      return true
    } catch (error) {
      return false
    }
  }

  const addFingerprint = async (id, data) => {
    try {
      await axiosRequest.post("users/fingerprints", {
        id,
        data,
      })
      return true
    } catch (error) {
      return false
    }
  }

  return {
    register,
    login,
    forgotPassword,
    resetPassword,
    confirmation,
    socialAuthentication,
    setRole,
    resendConfirmation,
    logout,
    addFingerprint,
  }
}

export default useAuthService
