//React imports
import React from "react"
import { components } from "react-select"
import AsyncSelect from "react-select/async"
import debounce from "lodash.debounce"
//Custom services
import useCampaignService from "../../../../../../services/useCampaignService"
//Custom styles
import "./styles.scss"
//Assets
import { LocationSmallIcon } from "../../../../../../assets/images/Campaign"

const LocationSearch = ({ handleChange }) => {
  const campaignService = useCampaignService()

  const loadOptions = debounce((value, callback) => {
    campaignService.locations(value).then((response) => {
      const options = response?.map((option) => ({
        label: option.name,
        value: { id: option.id, name: option.name, new: true },
      }))
      window.scrollTo(0, document.body.scrollHeight)
      callback(options)
    })
  }, 300)

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={LocationSmallIcon} alt="icon" />
      </components.DropdownIndicator>
    )
  }

  const customStyles = {
    control: (base) => ({
      ...base,
      flexDirection: "row-reverse",
    }),
    clearIndicator: (base) => ({
      ...base,
      position: "absolute",
      right: "10px",
    }),
    loadingIndicator: (base) => ({
      ...base,
      position: "absolute",
      right: "10px",
    }),
  }

  return (
    <div className="campignFormSearchInput">
      <AsyncSelect
        cacheOptions
        loadOptions={loadOptions}
        placeholder="Please enter your location"
        classNamePrefix="react-select"
        closeMenuOnSelect
        isClearable={false}
        components={{ DropdownIndicator }}
        value={null}
        onChange={handleChange}
        styles={customStyles}
        noOptionsMessage={() => null}
      />
    </div>
  )
}

export default LocationSearch
