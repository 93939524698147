// React
import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";

// Custom Component
import Header from "../../../components/Common/Header";
import { Button } from "../../../components/Common/CommonElements";
import { IconDropdownMenu } from "../../../components/Common/CommonElements";
import TemplateModal from "../../../components/Common/EmailTemplates/TemplateModal";
import ConfirmationModal from "../../../components/Common/ConfirmationModal";

// assets
import SearchIcon from "../../../assets/images/search.svg";

// Services
import useEmailTemplatesService from "../../../services/useEmailTemplatesService";

// style
import "./style.scss";

// component to render
const EmailTemplates = () => {
  // Use States
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [openComposeTemplateModal, setOpenComposeTemplateModal] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTemplateId, setSelectedTemplateId] = useState("");
  const [deleteTemplateConfirmationModal, setDeleteTemplateConfirmationModal] =
    useState(false);

  // Service Constants
  const emailTemplateService = useEmailTemplatesService();

  // Use Effects
  useEffect(() => {
    emailTemplateService.list(searchQuery).then((data) => {
      if (data) {
        setEmailTemplates(data);
      }
    });
  }, [searchQuery]);

  const handleTemplateEdit = (templateId) => {
    setSelectedTemplateId(templateId);
    setOpenComposeTemplateModal(true);
  };

  const handleTemplateDelete = () => {
    emailTemplateService
      .remove(selectedTemplateId, searchQuery)
      .then((data) => {
        if (data) {
          setEmailTemplates(data);
          setDeleteTemplateConfirmationModal(false);
        }
      });
  };

  const handleDeleteConfirmation = (templateId) => {
    setSelectedTemplateId(templateId);
    setDeleteTemplateConfirmationModal(true);
  };

  const handleTemplateSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  // Component to render

  return (
    <>
      <Header />
      <div className="p-4">
        <h4>Templates</h4>
        <div className="mt-4 d-flex justify-content-between align-items-center template-header-section">
          <div className="searchbar-container">
            <div className="search-icon-container">
              <img src={SearchIcon} className="search-icon" alt="" />
            </div>
            <input
              type="text"
              placeholder="Search..."
              className="searchbar"
              onChange={(e) => handleTemplateSearch(e)}
            />
          </div>
          <Button
            label="Add New Template"
            onClick={() => {
              setSelectedTemplateId("");
              setOpenComposeTemplateModal(true);
            }}
            theme={{
              backgroundColor: "#000000",
              color: "#ffffff",
              padding: "0.5rem 1.4rem",
              fontSize: "13px",
            }}
          />
        </div>
        <div className="template-list-section mt-4">
          <div className="template-list-section-header ms-1 me-1">
            <span className="header-font-14">Template Name</span>
            <span className="header-font-14">Actions</span>
          </div>
          <div className="template-list-body">
            {emailTemplates.map((template, index) => (
              <div
                key={index}
                className="template-list-row-border-section mt-3 mx-2"
              >
                <div>
                  <div className="list-row-header-font-13">{template.name}</div>
                  <div className="detail-gray-text">{template.updated_at}</div>
                </div>
                <IconDropdownMenu>
                  <MenuItem onClick={() => handleTemplateEdit(template.id)}>
                    <div className="mui-dropdown-menu-item-container">
                      <div
                        className="dropdown-item-text"
                        style={{ fontSize: "13px" }}
                      >
                        Edit
                      </div>
                    </div>
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleDeleteConfirmation(template.id)}
                  >
                    <div className="mui-dropdown-menu-item-container">
                      <div
                        className="dropdown-item-text"
                        style={{ color: "#EB5757", fontSize: "13px" }}
                      >
                        Delete
                      </div>
                    </div>
                  </MenuItem>
                </IconDropdownMenu>
              </div>
            ))}
          </div>
        </div>

        {openComposeTemplateModal && (
          <TemplateModal
            openTemplateModal={openComposeTemplateModal}
            setOpenTemplateModal={setOpenComposeTemplateModal}
            templateId={selectedTemplateId}
            setEmailTemplates={setEmailTemplates}
          />
        )}
        {deleteTemplateConfirmationModal && (
          <ConfirmationModal
            open={true}
            onClose={() => {
              setDeleteTemplateConfirmationModal(false);
            }}
            onConfirm={handleTemplateDelete}
            cancelButtonText={"Cancel"}
            confirmButtonText={"Delete"}
            confirmButtonColor={"#F65353"}
            modalHeadingText={"Delete Template"}
            confirmationText={`Are you sure you want to delete this template?`}
            cancelAlert={true}
          />
        )}
      </div>
    </>
  );
};

export default EmailTemplates;
