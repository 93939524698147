// React imports
import React from "react"
import CircularProgress from "@mui/material/CircularProgress"
// Custom styles
import "./styles.scss"

const FloatingControls = ({ handlePrev, handleNext, handlePreview, isFirstStep, isLastStep, displayErrors, isLoading, editMode }) => {
  return (
    <div className="campaignFormFloatingControls">
      {!editMode && !isFirstStep && (
        <button className="navButton navButtonLight" onClick={handlePrev} type="button" disabled={isLoading}>
          Back
        </button>
      )}
      <div className="flexContainer">
        {!editMode && !isLastStep && (
          <button className="navButton navButtonDark" onClick={handleNext} type="button" disabled={isLoading}>
            Next
          </button>
        )}
        {!editMode && isLastStep && (
          <button className="navButton navButtonSecondary" onClick={handlePreview} type="button" disabled={isLoading}>
            Preview Campaign
          </button>
        )}
        {(isLastStep || editMode) && (
          <button className="navButton navButtonPrimary" type="submit" onClick={displayErrors}>
            {isLoading ? <CircularProgress color="white" size={24} /> : "Submit"}
          </button>
        )}
      </div>
    </div>
  )
}

export default FloatingControls
