// React
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

// import MenuItem from "@mui/material/MenuItem";
import SimpleDropDown from "../../Common/SimpleDropDown";

// assets
import EditIcon from "../../../assets/images/edit.svg";
import RemoveIcon from "../../../assets/images/remove.svg";
import CopyLinkIcon from "../../../assets/images/copy_link.svg";
import WhiteDropdownIcon from "../../../assets/images/white_dropdown_icon.svg";
import BlackDropdownIcon from "../../../assets/images/icons/black_dropdown.svg";
import PreviewIcon from "../../../assets/images/eye.svg";
// Services
import useCampaignService from "../../../services/useCampaignService";
// style
import "./style.scss";

// component to render
const CampaignListDetailRow = ({ campaign, index, handleCampaignDelete }) => {
  // React constants
  const navigate = useNavigate();

  // Service Constants

  const campaignService = useCampaignService();
  // Use States
  const [campaignObj, setCampaignObj] = useState(campaign);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  // Use Effect

  useEffect(() => {}, [campaignObj]);

  // Handlers
  const handleCopyPublicLink = () => {
    navigator.clipboard
      .writeText(campaignObj.public_url)
      .then(() => {
        setTooltipVisible(true);
        setTimeout(() => setTooltipVisible(false), 1000);
      })
      .catch((err) => {
        console.error("Failed to copy text:", err);
      });
  };

  const handleStatusChange = (status) => {
    campaignService.changeStatus(campaignObj.id, status).then((data) => {
      if (data) {
        setCampaignObj(data);
      }
    });
  };

  // Component to render

  return (
    <>
      <div
        key={index}
        className="row campaign-list-row-border-section mt-3 mx-2"
      >
        <div className="col-4 d-flex align-items-center">
          <img
            src={campaignObj.thumbnail_url}
            style={{
              width: "70px",
              height: "70px",
              objectFit: "cover",
              borderRadius: "15px",
            }}
          />
          <div className="ms-3">
            <div className="list-row-header-font-13 text-capitalize">
              {campaignObj.title}
            </div>
            <div className="detail-gray-text mt-1">
              {campaignObj.updated_at}
            </div>
          </div>
        </div>
        <div className="col-3 list-row-header-font-13 mt-4">
          {campaignObj.locations.join(", ")}
        </div>
        <div
          className={`col-2 list-row-header-font-13 mt-4 text-success ${
            campaignObj.proposals > 0 ? "text-underline" : ""
          }`}
        >
          {campaignObj.proposals} Proposals
        </div>
        <div className="col-2 mt-3">
          <SimpleDropDown
            dropdownTitle={campaignObj.status}
            menuOptions={[
              { title: campaignObj.status == "active" ? "inactive" : "active" },
            ]}
            customStyle={{
              fontSize: "12px",
              textTransform: "capitalize",
              borderRadius: "20px",
              padding: "2px 10px",
              marginTop: "5px",
              backgroundColor: `${
                campaignObj.status == "active" ? "#28ae60" : "#f9fafb"
              }`,
              color: `${campaignObj.status == "active" ? "white" : "black"}`,
              border: `1px solid ${
                campaignObj.status == "active" ? "#28ae60" : "#f9fafb"
              }`,
            }}
            dropdownToggleIcon={
              campaignObj.status == "active"
                ? WhiteDropdownIcon
                : BlackDropdownIcon
            }
            handleMenuSelectedClick={handleStatusChange}
          />
        </div>
        <div className="col-1 d-flex align-items-center gap-2">
          <img
            src={PreviewIcon}
            onClick={() => {
              navigate(`/brand/campaigns/${campaignObj.slug}`);
            }}
            style={{
              width: "18px",
              cursor: "pointer",
            }}
          />
          <img
            src={EditIcon}
            onClick={() => {
              navigate(`/brand/campaigns/${campaignObj.slug}/edit`);
            }}
            style={{
              width: "18px",
              cursor: "pointer",
            }}
          />
          <Tooltip
            title="Link Copied"
            placement="top"
            open={tooltipVisible}
            arrow
          >
            <img
              src={CopyLinkIcon}
              onClick={handleCopyPublicLink}
              style={{
                width: "18px",
                cursor: "pointer",
              }}
            />
          </Tooltip>

          <img
            src={RemoveIcon}
            onClick={() => {
              handleCampaignDelete(campaignObj.id);
            }}
            style={{
              width: "18px",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
    </>
  );
};

export default CampaignListDetailRow;
