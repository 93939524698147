//React imports
import React, { useEffect, useState } from "react"
//Custom component imports
import OptionCard from "../Common/OptionCard"
import RangeSlider from "../Common/RangeSlider"
import TextInput from "../Common/TextInput"
//Custom styles
import "./styles.scss"
//Custom helpers
import { cardData } from "./helper"
// Assets
import { EmailIcon } from "../../../../../assets/images/Campaign"

const Networks = ({ values: { creatorType, networks, followers }, setFieldValue, errors, displayErrors }) => {
  const [otherNetwork, setOtherNetwork] = useState()
  const [otherNetworkSelected, setOtherNetworkSelected] = useState(false)

  const displayedCards = cardData[creatorType]
  const cardValues = cardData[creatorType].map((card) => card.value)

  useEffect(() => {
    const other = networks.find((network) => !cardValues.includes(network?.name))
    if (other) {
      setOtherNetworkSelected(true)
      setOtherNetwork(other)
    }
  }, [])

  useEffect(() => {
    const filteredNetworks = networks.filter((network) => cardValues.includes(network?.name))

    if (otherNetwork && otherNetwork?.name !== "") {
      if (otherNetwork?.id) {
        filteredNetworks.push(otherNetwork)
      } else {
        filteredNetworks.push({ name: otherNetwork?.name, new: true })
      }

      setFieldValue("networks", filteredNetworks)
    }
  }, [otherNetwork])

  const addNetwork = (network) => {
    const index = networks.findIndex((net) => net?.name === network)

    if (index !== -1) {
      const updatedNetworks = [...networks]

      if (updatedNetworks[index].new) {
        updatedNetworks.splice(index, 1)
      } else {
        updatedNetworks[index] = {
          ...updatedNetworks[index],
          _destroy: updatedNetworks[index]._destroy ? undefined : true,
        }
      }

      setFieldValue("networks", updatedNetworks)
    } else {
      setFieldValue("networks", [...networks, { name: network, new: true }])
    }
  }

  const toggleOtherNetworkSelected = () => {
    setOtherNetworkSelected(!otherNetworkSelected)
    if (otherNetwork?.id) {
      const updatedNetworks = networks.map((network) => {
        if (network.id === otherNetwork.id) {
          return {
            ...network,
            _destroy: true,
          }
        }
        return network
      })
      setFieldValue("networks", updatedNetworks)
    } else {
      setFieldValue(
        "networks",
        networks.filter((network) => network?.name !== otherNetwork?.name)
      )
    }
    setOtherNetwork(null)
  }

  const addOtherNetwork = (value) => {
    setOtherNetwork({ name: value, id: otherNetwork?.id })
  }

  const setFollowers = (value) => {
    setFieldValue("followers", value)
  }

  return (
    <div className="campaignFormNetworksStep">
      <div className="networkSelectionContainer">
        <div className="heading">Choose one or more networks</div>
        <div className="networks">
          {displayedCards.map((card, index) => (
            <OptionCard
              key={index}
              title={card.title}
              description={card.description}
              icon={card.icon}
              type="checkbox"
              selected={networks?.some((network) => network?.name === card.value && network?._destroy !== true)}
              onClick={() => addNetwork(card.value)}
            />
          ))}
          {creatorType === "ugc" && (
            <OptionCard
              title="Other"
              description="Specify other platforms or ways you plan to use this content (e.g., email marketing, in-store displays, newsletters"
              type="checkbox"
              icon={EmailIcon}
              selected={otherNetworkSelected}
              onClick={toggleOtherNetworkSelected}
            />
          )}
          {otherNetworkSelected && (
            <div className="inputContainer">
              <TextInput label="Specify the other network here" value={otherNetwork?.name || ""} setValue={addOtherNetwork} limit={30} />
            </div>
          )}
        </div>
        {displayErrors && errors?.networks && <div className="errorMessage">{errors.networks}</div>}
      </div>
      {creatorType === "marketing" && (
        <div className="followersContainer">
          <div className="heading">What's the minimum number of followers ?</div>
          <RangeSlider min={1000} max={1000000} steps={1000} value={followers} setValue={setFollowers} tooltipDisplay="auto" legendDisplay />
          {displayErrors && errors?.followers && <div className="errorMessage">{errors.followers}</div>}
        </div>
      )}
    </div>
  )
}

export default Networks
