// React imports
import React from "react"
import TagsInput from "react-tagsinput"
// Custom styles
import "./styles.scss"
import "react-tagsinput/react-tagsinput.css"

const TagsEnterInput = ({ label, subtext, value, handleChange }) => {
  return (
    <div className="campaignFormTagsInput">
      <div className="label">
        {label} <span className="subtext">{subtext}</span>
      </div>
      <TagsInput
        value={value}
        onChange={handleChange}
        onlyUnique
        inputProps={{
          placeholder: "Enter...",
        }}
      />
    </div>
  )
}

export default TagsEnterInput
