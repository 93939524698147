import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  influencers: null,
  currentPlatform: "instagram",
  currentFilters: {},
  appliedFilters: {},
  currentSorting: { label: "Followers", value: "followers" },
  currentPage: 1,
  totalItems: 0,
  totalResults: null,
}

const influencerSlice = createSlice({
  name: "influencer",
  initialState,
  reducers: {
    updateInfluencers: (state, action) => {
      state.influencers = action.payload
    },
    updatePlatform: (state, action) => {
      state.currentPlatform = action.payload
    },
    updateCurrentFilters: (state, action) => {
      state.currentFilters = action.payload
    },
    updateAppliedFilters: (state, action) => {
      state.appliedFilters = action.payload
    },
    updateSorting: (state, action) => {
      state.currentSorting = action.payload
    },
    updatePage: (state, action) => {
      state.currentPage = action.payload
    },
    updateTotalItems: (state, action) => {
      state.totalItems = action.payload
    },
    updateTotalResults: (state, action) => {
      state.totalResults = action.payload
    },
    resetState: () => initialState,
  },
})

export const { updateInfluencers, updatePlatform, updateCurrentFilters, updateAppliedFilters, updateSorting, updatePage, updateTotalItems, updateTotalResults, resetState } = influencerSlice.actions

export default influencerSlice.reducer
