//React imports
import React, { useState } from "react"
//Custom component imports
import ImageDropzone from "../Common/ImageDropzone"
import ImageUpload from "../Common/ImageUpload"
import ImageCropper from "../Common/ImageCropper"
//Custom styles
import "./styles.scss"

const ImageUploader = ({ values: { images, deletedImages }, setFieldValue, errors, displayErrors }) => {
  const [isCropperOpen, setCropperOpen] = useState(false)
  const [selectedImage, setSelectedImage] = useState()

  const setFiles = (file) => {
    setSelectedImage(URL.createObjectURL(file[0]))
    setCropperOpen(true)
  }

  const handleRemove = (image) => {
    const updatedImages = images.filter((file) => file.preview !== image.preview)

    if (image.id) {
      setFieldValue("deletedImages", [...deletedImages, image.id])
    }

    setFieldValue("images", updatedImages)
  }

  const handleCropComplete = (croppedImage) => {
    setFieldValue("images", [...images, croppedImage])
    setCropperOpen(false)
    setSelectedImage(null)
  }

  const handleCropCancel = () => {
    setCropperOpen(false)
    setSelectedImage(null)
  }

  return (
    <div className="campaignFormImageUploaderStep">
      <div className="heading">Add some images to make your offer stand out</div>
      {displayErrors && errors?.images && <div className="errorMessage">{errors.images}</div>}
      <ImageDropzone setFiles={setFiles} />
      <div className="imagesContainer">
        {images?.map((image, index) => (
          <ImageUpload key={index} image={image.preview} handleRemove={() => handleRemove(image)} />
        ))}
      </div>
      <ImageCropper isOpen={isCropperOpen} imageSrc={selectedImage} onCropComplete={handleCropComplete} onClose={handleCropCancel} />
    </div>
  )
}

export default ImageUploader
