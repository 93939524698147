import { AdIcon, ShareIcon, GlobeIcon } from "../../../../../assets/images/Campaign"
import { InstagramIcon, YoutubeIcon, TiktokIcon } from "../../../../../assets/images/Common"
import * as Yup from "yup"

export const NetworksValidationSchema = Yup.object().shape({
  networks: Yup.array()
    .test("at-least-one-not-destroyed", "Please select at least one network.", (networks) => {
      return networks.some((network) => network._destroy !== true)
    })
    .test("unique-networks", "Networks must be unique.", (networks) => {
      const validNetworks = networks.filter((network) => network._destroy !== true)
      const names = validNetworks.map((network) => network.name.toLowerCase())
      const uniqueNames = new Set(names)
      return uniqueNames.size === names.length
    })
    .test("non-empty-names", "Network names cannot be empty.", (networks) => {
      const validNetworks = networks.filter((network) => network._destroy !== true)
      return validNetworks.every((network) => network.name.trim() !== "")
    }),
  followers: Yup.number().required("Number of followers is required.").typeError("Followers must be a number."),
})

export const cardData = {
  marketing: [
    {
      title: "Instagram",
      description: "Collaborate with Instagram creators and have them post photos, reels, stories, and livestreams.",
      icon: InstagramIcon,
      value: "Instagram",
    },
    {
      title: "Youtube",
      description: "Partner with Youtube creators to produce videos and livestreams for your campaign.",
      icon: YoutubeIcon,
      value: "Youtube",
    },
    {
      title: "TikTok",
      description: "Work with TikTok influencers to create engaging content like videos, reels, and livestreams.",
      icon: TiktokIcon,
      value: "TikTok",
    },
  ],
  ugc: [
    {
      title: "Paid Advertising",
      description: "Utilize UGC for paid promotions, such as ads on Google, Facebook, or Instagram.",
      icon: AdIcon,
      value: "Paid Advertising",
    },
    {
      title: "Social Media Channels",
      description: "Share UGC on social platforms like Instagram, TikTok, YouTube, and others to engage your audience.",
      icon: ShareIcon,
      value: "Social Media Channels",
    },
    {
      title: "Brand Website",
      description: "Use content on your official brand website to promote products and services.",
      icon: GlobeIcon,
      value: "Brand Website",
    },
  ],
}
