// React
import React, { useState, useEffect } from "react";
import ReactJsPagination from "react-js-pagination";
import Select from "react-select";

// Custom Component
import Header from "../../../../components/Common/Header";
import { Button } from "../../../../components/Common/CommonElements";
import ConfirmationModal from "../../../../components/Common/ConfirmationModal";
import CampaignListDetailRow from "../../../../components/Campaigns/CampaignListDetailRow";
import Wizard from "../../../../components/Campaigns/Wizard";

// assets
import SearchIcon from "../../../../assets/images/search.svg";

// Services
import useCampaignService from "../../../../services/useCampaignService";

// style
import "./style.scss";

// component to render
const Listing = () => {
  // Routing

  // Use States
  const [campaigns, setCampaigns] = useState([]);
  const [campaignListLoading, setCampaignListLoading] = useState(false);
  const [selectedFilterOption, setSelectedFilterOption] = useState("");
  const [pagination, setPagination] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [deleteCampaignConfirmationModal, setDeleteCampaignConfirmationModal] =
    useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCampaignId, setSelectedCampaignId] = useState("");
  const [wizardIsOpen, setWizardOpen] = useState(false);

  // Service Constants
  const campaignService = useCampaignService();

  //  Use Effects
  useEffect(() => {
    setCampaigns([]);
    campaignService
      .list(searchQuery, pageNo, selectedFilterOption?.value)
      .then((data) => {
        if (data) {
          setCampaigns(data.campaigns);
          setPagination(data.meta.pagination);
        }
      });
  }, [searchQuery, pageNo, selectedFilterOption, campaignListLoading]);

  // handlers
  const handleCampaignSearch = (event) => {
    setSearchQuery(event.target.value);
    setPageNo(1);
  };

  const handleCampaignDeleteWarning = (campaignId) => {
    setSelectedCampaignId(campaignId);
    setDeleteCampaignConfirmationModal(true);
  };

  const handleCampaignDelete = () => {
    campaignService.remove(selectedCampaignId, searchQuery).then((data) => {
      if (data) {
        setCampaigns(data.campaigns);
        setPagination(data.meta.pagination);
        setPageNo(1);
        setDeleteCampaignConfirmationModal(false);
      }
    });
  };

  const handleCampaignFilterChange = (option) => {
    setPageNo(1);
    setSelectedFilterOption(option);
  };

  const handleOpenWizard = () => {
    setWizardOpen(true);
  };

  const handleCloseWizard = () => {
    setWizardOpen(false);
  };

  // Helpers

  const filerOptions = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
  ];

  const smallSelectCustomStyles = {
    control: (provided) => ({
      ...provided,
      margin: "1px",
      border: "1px solid #E2E8F0",
      borderRadius: "20px",
      marginBottom: "5px",
      marginTop: "5px",
      marginLeft: "10px",
      fontSize: "14px",
      fontWeight: "500",
      color: "#000000",
      backgroundColor: "#FFF",
      width: "147px",
      "&:hover": {
        cursor: "pointer",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#C4CCFF"
        : state.isFocused
        ? "lightgray"
        : "white",
      color: "black",
      width: "100%",
      "&:hover": {
        cursor: "pointer",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#000000",
      fontWeight: "400",
      fontSize: "11px",
    }),
  };

  // Component to render

  return (
    <>
      <Header />
      <div className="p-4">
        <h4>Campaigns</h4>
        <div className="mt-4 d-flex justify-content-between align-items-center template-header-section">
          <div className="d-flex align-items-center">
            <div className="searchbar-container" style={{ width: "470px" }}>
              <div className="search-icon-container">
                <img src={SearchIcon} className="search-icon" alt="" />
              </div>
              <input
                type="text"
                placeholder="Search..."
                className="searchbar"
                onChange={(e) => handleCampaignSearch(e)}
              />
            </div>
            <Select
              options={filerOptions}
              closeMenuOnSelect={true}
              onChange={handleCampaignFilterChange}
              value={selectedFilterOption}
              styles={smallSelectCustomStyles}
              placeholder="Filter by status"
              isClearable
            />
          </div>

          <Button
            label="Post a Campaign"
            onClick={() => {
              handleOpenWizard();
            }}
            theme={{
              backgroundColor: "#000000",
              color: "#ffffff",
              padding: "0.5rem 1.4rem",
              fontSize: "13px",
            }}
          />
        </div>
        <div className="campaign-list-section mt-4">
          <div className="campaign-list-section-header row ms-1 me-1">
            <span className="col-4">Offer</span>
            <span className="col-3">Location</span>
            <span className="col-2">Proposals</span>
            <span className="col-2">Status</span>
            <span className="col-1">Actions</span>
          </div>
          <div className="campaign-list-body">
            {campaigns.length > 0 &&
              campaigns.map((campaign, index) => (
                <CampaignListDetailRow
                  campaign={campaign}
                  index={index}
                  handleCampaignDelete={handleCampaignDeleteWarning}
                />
              ))}
          </div>
          {campaigns.length > 0 && (
            <div className="mt-3 mb-0 d-flex justify-content-center campaign-pagination-container">
              <ReactJsPagination
                activePage={pagination.page}
                itemsCountPerPage={pagination.items}
                totalItemsCount={pagination.count}
                pageRangeDisplayed={5}
                onChange={(data) => {
                  setPageNo(data);
                }}
                prevPageText={"<"}
                nextPageText={">"}
                itemClass={"page-item"}
                linkClass={"page-link"}
                activeClass={"active"}
                hideFirstLastPages={true}
              />
            </div>
          )}
        </div>

        {deleteCampaignConfirmationModal && (
          <ConfirmationModal
            open={true}
            onClose={() => {
              setDeleteCampaignConfirmationModal(false);
            }}
            onConfirm={handleCampaignDelete}
            cancelButtonText={"Cancel"}
            confirmButtonText={"Delete"}
            confirmButtonColor={"#F65353"}
            modalHeadingText={"Delete Template"}
            confirmationText={`Are you sure you want to delete this template?`}
            cancelAlert={true}
          />
        )}
      </div>

      <Wizard
        wizardIsOpen={wizardIsOpen}
        closeWizard={handleCloseWizard}
        campaignListLoading ={campaignListLoading}
        setCampaignListLoading={setCampaignListLoading}
      />
    </>
  );
};

export default Listing;
