import React, { useState } from "react"
import { Dialog } from "@mui/material"
// Custom component imports
import CategoryCard from "../CategoryCard"
// Custom styles
import "./styles.scss"
// Assets
import { CrossIcon, SearchIcon } from "../../../../../../assets/images/Common"

const CategoryListing = ({ categories, setCategory, selectedCategory, isOpen, onClose }) => {
  const [searchQuery, setSearchQuery] = useState("")

  const filteredCategories = categories.filter((category) => category.name.toLowerCase().includes(searchQuery.toLowerCase()))

  return (
    <Dialog open={isOpen} onClose={onClose} className="campaignFormCategoryListing">
      <div className="modalContainer">
        <div className="header">
          <div className="heading">Choose the category that best describes your offer</div>
          <div className="closeButton" onClick={onClose}>
            <img src={CrossIcon} alt="" className="icon" />
          </div>
        </div>
        <div className="searchBarContainer">
          <img src={SearchIcon} alt="" className="icon" />
          <input type="text" className="searchBar" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} placeholder="Search..." />
        </div>
        <div className="categoryCardsContainer">
          {filteredCategories.map((object, index) => (
            <CategoryCard key={index} label={object.name} image={object.thumbnail} selected={selectedCategory?.id === object.id} onClick={() => setCategory(object)} />
          ))}
        </div>
        <div className="footer">
          <button className="submitButton" onClick={onClose}>
            Confirm
          </button>
        </div>
      </div>
    </Dialog>
  )
}

export default CategoryListing
