// React
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// Custom Component
import { FullScreenLoader } from "../../../components/Common/Loaders";
import CampaignPreview from "../../../components/Campaigns/CampaignPreview";
// assets

// Services
import useCampaignService from "../../../services/useCampaignService";

// style
import "./style.scss";

// component to render
const CampaignPublicPage = () => {
  //helper constants
  const { slug } = useParams();

  // Service Constants
  const campaignService = useCampaignService();

  // Use States
  const [campaign, setCampaign] = useState("");
  const [loaderState, setLoaderState] = useState(true);

  // Use Effects
  useEffect(() => {
    campaignService.publicVisibleCampaign(slug).then((data) => {
      if (data) {
        setCampaign(data);
      }
      setLoaderState(false);
    });
  }, []);

  // Component to render

  return (
    <>
      {campaign && <CampaignPreview campaign={campaign} />}
      {loaderState && <FullScreenLoader state={true} />}
      {!loaderState && !campaign && (
        <div className="no-campaign-text">Campaign no longer avaialable</div>
      )}
    </>
  );
};

export default CampaignPublicPage;
