//React imports
import React from "react"
import Select, { components } from "react-select"
//Custom styles
import "./styles.scss"
//Assets
import { DropdownIcon } from "../../../../../../assets/images/Common"

const SelectInput = ({ label, value, handleChange, options }) => {
  const DropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
      <img src={DropdownIcon} alt="" />
    </components.DropdownIndicator>
  )

  return (
    <div className="campaignFormSelectInput">
      <div className="label">{label}</div>
      <Select classNamePrefix="react-select" options={options} placeholder="Select..." value={value} onChange={handleChange} components={{ DropdownIndicator }} />
    </div>
  )
}

export default SelectInput
