// React import
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import notify from "../../../components/Common/Toast";

// Assets import

// Custom Components
import { FullScreenLoader } from "../../../components/Common/Loaders";

// Services
import useMailboxService from "../../../services/useMailboxService";
import { setUser } from "../../../redux/reducers/authReducer";

// Component to render
const MailBoxCallback = () => {
  // constants
  const queryParams = new URLSearchParams(useLocation().search);
  const authorizationCode = queryParams.get("code");

  //service constants
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mailboxHookService = useMailboxService();

  //Use Effects
  useEffect(() => {
    mailboxHookService
      .verifyAuthorizationCode(authorizationCode)
      .then((result) => {
        if (result) {
          dispatch(setUser(result));
        } else {
          notify.error("An error occured while connecting your mailbox");
        }
        navigate(`/brand/dashboard`);
      })
      .catch((error) => {
        console.log(error.response.data.error);
      });
  }, []);

  // Html
  return (
    <>
      <FullScreenLoader state={true} />
    </>
  );
};

export default MailBoxCallback;
