import Offers from "./components/Offers"
import Details from "./components/Details"
import Locations from "./components/Locations"
import Networks from "./components/Networks"
import ImageUploader from "./components/ImageUploader"
import { OffersValidationSchema } from "./components/Offers/helper"
import { DetailsValidationSchema } from "./components/Details/helper"
import { LocationsValidationSchema } from "./components/Locations/helper"
import { NetworksValidationSchema } from "./components/Networks/helper"
import { ImageUploaderValidationSchema } from "./components/ImageUploader/helper"

export const steps = [
  {
    component: Offers,
    validationSchema: OffersValidationSchema,
    name: "Offer Incentives",
    heading: "What Incentives Are You Offering to Creators?",
  },
  {
    component: Details,
    validationSchema: DetailsValidationSchema,
    name: "Campaign Details",
    heading: "Give Creators Some Details About Your Campaign",
  },
  {
    component: Locations,
    validationSchema: LocationsValidationSchema,
    name: "Location & Category",
    heading: "Define Your Campaign Theme and Target Location",
  },
  {
    component: Networks,
    validationSchema: NetworksValidationSchema,
    name: "Add Networks",
    heading: "Select Networks for Your Campaign",
  },
  {
    component: ImageUploader,
    validationSchema: ImageUploaderValidationSchema,
    name: "Add Images",
    heading: "Showcase Your Campaign with Stunning Visuals",
  },
]

export const stepNames = steps.map((step) => step.name)

export const defaultValues = {
  creatorType: "marketing",
  incentives: {
    fee: { selected: false, value: null },
    product: { selected: false, value: null, description: "delivery" },
    commission: { selected: false, value: null },
  },
  title: "",
  description: null,
  hashtags: [],
  mentions: [],
  category: null,
  locations: [],
  networks: [],
  followers: 1000,
  images: [],
}

export const parseCampaignObject = (object) => {
  const { title, description, category, hashtags, mentions, creatorType, followers, locations, networks, incentives, images, deletedImages } = object

  const formData = new FormData()

  const appendkeyValue = (key, value) => {
    if (value !== undefined && value !== null) {
      formData.append(key, value)
    }
  }

  appendkeyValue("campaign[title]", title)
  appendkeyValue("campaign[description]", description)
  appendkeyValue("campaign[content_type]", creatorType)
  appendkeyValue("campaign[min_followers]", followers)
  appendkeyValue("campaign[campaign_category_id]", category?.id)

  hashtags &&
    hashtags.forEach((hashtag) => {
      formData.append("campaign[hashtags][]", hashtag)
    })

  mentions &&
    mentions.forEach((mention) => {
      formData.append("campaign[mentions][]", mention)
    })

  locations &&
    locations.forEach((location, index) => {
      if (location?.new || location?._destroy) {
        const key = location.new ? "location_id" : "id"
        formData.append(`campaign[campaign_locations_attributes][${index}][${key}]`, location.id)

        if (location?._destroy) {
          formData.append(`campaign[campaign_locations_attributes][${index}][_destroy]`, location._destroy)
        }
      }
    })

  networks &&
    networks.forEach((network, index) => {
      if (network?.id) {
        formData.append(`campaign[campaign_networks_attributes][${index}][id]`, network.id)
      }

      formData.append(`campaign[campaign_networks_attributes][${index}][name]`, network.name.trim())

      if (network?._destroy) {
        formData.append(`campaign[campaign_networks_attributes][${index}][_destroy]`, network._destroy)
      }
    })

  let campaign_offers_attributes

  if (incentives) {
    campaign_offers_attributes = Object.keys(incentives)
      .filter((key) => incentives[key].selected)
      .map((key) => ({
        incentive: key,
        value: incentives[key].value,
        description: incentives[key].description || null,
      }))
  }

  campaign_offers_attributes?.forEach((offer, index) => {
    formData.append(`campaign[campaign_offers_attributes][${index}][incentive]`, offer.incentive)

    let formattedValue = offer.value

    if (offer.incentive === "product") {
      formattedValue = `$${offer.value}`
    } else if (offer.incentive === "commission") {
      formattedValue = `${offer.value}%`
    } else if (offer.incentive === "fee" && creatorType === "ugc") {
      formattedValue = `$${offer.value}`
    }

    appendkeyValue(`campaign[campaign_offers_attributes][${index}][value]`, formattedValue)
    offer.description && appendkeyValue(`campaign[campaign_offers_attributes][${index}][description]`, offer.description)
  })

  images?.forEach((image) => {
    if (!image?.id) {
      const { name, lastModified, type } = image
      const file = new File([image], name, {
        type: type,
        lastModified: lastModified,
      })
      formData.append("campaign[images][]", file)
    }
  })

  if (deletedImages && deletedImages.length > 0) {
    deletedImages.forEach((imageId) => {
      formData.append("deleted_images[]", imageId)
    })
  }

  return formData
}

export const parsePreviewObject = (object, currentUser) => {
  let offers = Object.entries(object.incentives)
    .filter(([key, offer]) => offer.selected)
    .map(([key, offer]) => {
      let description = ""

      if (key === "fee") {
        description = `Budget: ${offer.value} USD`
      } else if (key === "product") {
        description = `${offer.value} Gift value (${offer.description})`
      } else if (key === "commission") {
        description = `${offer.value}% Commission`
      }

      return {
        incentive: key,
        description: description,
      }
    })

  let locations = object.locations?.map((location) => ({
    name: location.id,
  }))

  locations = !locations && ["Worldwide"]

  let images = []

  object.images.forEach((image) => {
    const { preview } = image
    images.push(preview)
  })

  const hash = {
    company_name: currentUser.name,
    company_logo: currentUser.profile_picture_url,
    updated_at: "Now",
    content_type: object.creatorType,
    title: object.title,
    description: object.description,
    offers: offers,
    hashtags: object.hashtags,
    mentions: object.mentions,
    category: object.category,
    locations: locations,
    networks: object.networks,
    min_followers: object.followers,
    images: images,
  }

  return hash
}
