import React from "react";
import Spinner from "react-bootstrap/Spinner";

const GrowLoader = () => {
  return (
    <div className="d-flex justify-content-center align-items-center h-100 gap-1">
      <Spinner
        animation="grow"
        style={{ backgroundColor: "#94A3FF" }}
        size="sm"
      />
      <Spinner
        animation="grow"
        style={{ backgroundColor: "#94A3FF" }}
        size="sm"
      />
      <Spinner
        animation="grow"
        size="sm"
        style={{ backgroundColor: "#94A3FF" }}
      />
      <Spinner
        animation="grow"
        size="sm"
        style={{ backgroundColor: "#94A3FF" }}
      />
      <Spinner
        animation="grow"
        size="sm"
        style={{ backgroundColor: "#94A3FF" }}
      />
    </div>
  );
};

export default GrowLoader;
