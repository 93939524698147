// React
import React, { useState } from "react";
import Button from "@mui/material/Button";
import DetailIcon from "@mui/icons-material/Apps";
import Tooltip from "@mui/material/Tooltip";
// Custom Component
import CampaignImagesViewerModal from "../CampaignImagesViewerModal";
// assets
import PurpleDollarIcon from "../../../assets/images/icons/purple_dollar_icon.svg";
import PurpleGiftIcon from "../../../assets/images/icons/purple_gift_icon.svg";
import PurpleLocationIcon from "../../../assets/images/icons/purple_location_icon.svg";
import PurpleMultipleIcon from "../../../assets/images/icons/purple_multiple_user_icon.svg";
import PurpleNetworkIcon from "../../../assets/images/icons/purple_network_icon.svg";
import PurpleCommission from "../../../assets/images/icons/purple_commisssion.svg";
import PurpleCollaboration from "../../../assets/images/icons/purple_collaboration.svg";

// style
import "./style.scss";

// component to render
const CampaignPreview = ({ campaign }) => {
  // Use States
  const [openCampaignImagesModal, setOpenCampaignImagesModal] = useState(false);

  // Helpers
  const OfferIcons = {
    fee: PurpleDollarIcon,
    product: PurpleGiftIcon,
    commission: PurpleCommission,
  };

  const collaborationTexts = {
    ugc: "User Generated Content",
    marketing: "Influencer Marketing",
  };

  const locations = campaign.locations.map((loc) => loc.name);
  const networks = campaign.networks.map((net) => net.name);

  // Component to render

  return (
    <>
      <div className="campaign-preview-container">
        <div className="d-flex justify-content-center gap-3 header-images-container">
          {campaign.images.slice(0, 3).map((image, index) => (
            <>
              {index === 2 ? (
                <div className="header-image-container">
                  <img
                    key={index}
                    src={image.preview}
                    alt={`showcase-${index}`}
                    className={"header-image"}
                  />
                  {campaign.images.length > 3 && (
                    <Button
                      className="see-more-btn"
                      variant="contained"
                      startIcon={<DetailIcon />}
                      onClick={() => {
                        setOpenCampaignImagesModal(true);
                      }}
                    >
                      See More
                    </Button>
                  )}
                </div>
              ) : (
                <img
                  key={index}
                  src={image.preview}
                  alt={`showcase-${index}`}
                  className={"header-image"}
                />
              )}
            </>
          ))}
        </div>
        <div className="detail-container">
          <div className="content-container">
            <div className="">
              <span>
                <img
                  className="company-logo"
                  src={campaign.company_logo}
                  alt="company-logo"
                />
              </span>
              <span className="font-18 ms-2 text-uppercase">
                {campaign.company_name} |{" "}
              </span>
              <span className="date-section font-15 mt-2 ms-1">
                Updated: {campaign.updated_at}
              </span>
            </div>
            <div className="mt-4">
              <span className="font-weight-300">Category: </span>
              <span className="section-heading">{campaign.category.name}</span>
            </div>

            <div className="mt-4">
              <h3>{campaign.title}</h3>
            </div>

            <div className="mt-4">
              <div className="section-heading">
                What's present in this offer
              </div>

              <div className="incentive-section mt-3">
                {campaign.offers.map((offer, index) => {
                  return (
                    <div className="mt-2" key={`offer-${index}`}>
                      <img src={OfferIcons[offer.incentive]} alt="incentive" />
                      <span className="ms-2 description-text">
                        {offer.description}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="mt-4">
              <div className="section-heading">Creater Requirements</div>
              <div className="incentive-section mt-3">
                <div>
                  <img src={PurpleLocationIcon} alt="location" />
                  <span className="ms-2 me-1 description-text">
                    {locations[0]}
                  </span>
                  {locations.length > 1 && (
                    <Tooltip
                      title={
                        <span style={{ whiteSpace: "pre-line" }}>
                          {locations.slice(1).join("\n")}
                        </span>
                      }
                      placement="top-end"
                      arrow
                    >
                      <span className="description-text">and</span>
                      <span className="tooltip-location-text">
                        {locations.length - 1} more
                      </span>
                    </Tooltip>
                  )}
                </div>
                <div className="mt-2">
                  <img src={PurpleCollaboration} alt="multiple" />
                  <span className="ms-2 description-text">
                    Collaboration: {collaborationTexts[campaign.content_type]}
                  </span>
                </div>
                <div className="mt-2">
                  <img src={PurpleNetworkIcon} alt="network" />
                  <span className="ms-2 description-text text-capitalize">
                    {campaign.content_type === "ugc"
                      ? "Networks: "
                      : "Platforms: "}
                    {networks.join(", ")}
                  </span>
                </div>
                <div className="mt-2">
                  <img src={PurpleMultipleIcon} alt="multiple" />
                  <span className="ms-2 description-text">
                    Minimum Followers: {campaign.min_followers}
                  </span>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <div className="section-heading">Offer Description</div>
              <div className="incentive-section mt-3">
                <div className="description-text pt-3 pb-3">
                  <div
                    dangerouslySetInnerHTML={{ __html: campaign.description }}
                  />
                </div>
              </div>
            </div>

            <div className="mt-4">
              <div className="section-heading">Tags</div>
              <div className="incentive-section mt-3">
                {campaign.hashtags.map((tag, index) => {
                  return (
                    <span
                      key={`tag-${index}`}
                      style={{
                        backgroundColor: "#F6F6F6",
                        borderRadius: "5px",
                        padding: "5px 10px",
                        fontSize: "11px",
                        color: "#808080",
                        marginRight: "10px",
                      }}
                    >
                      {tag}
                    </span>
                  );
                })}
              </div>
            </div>
            <div className="mt-4">
              <div className="section-heading">Mentions</div>
              <div className="incentive-section mt-3">
                {campaign.mentions.map((tag, index) => {
                  return (
                    <span
                      key={`tag-${index}`}
                      style={{
                        backgroundColor: "#F6F6F6",
                        borderRadius: "5px",
                        padding: "5px 10px",
                        fontSize: "11px",
                        color: "#808080",
                        marginRight: "10px",
                      }}
                    >
                      {tag}
                    </span>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      {campaign.images.length > 3 && (
        <CampaignImagesViewerModal
          openCampaignImagesModal={openCampaignImagesModal}
          setOpenCampaignImagesModal={setOpenCampaignImagesModal}
          images={campaign.images}
        />
      )}
    </>
  );
};

export default CampaignPreview;
