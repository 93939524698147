//React Imports
import React, { useState } from "react";
//Custom Components
import { Button } from "../../Common/CommonElements";
import Wizard from "../Wizard";
// Styles
import "./style.scss";

const CampaignEditView = ({ campaign, setCampaign }) => {
  const [wizard, setWizard] = useState(null);

  const {
    id,
    content_type,
    title,
    description,
    hashtags,
    mentions,
    category,
    locations,
    networks,
    min_followers,
    images,
  } = campaign;

  const editSteps = [
    {
      stepNumber: 1,
      initialValues: {
        id: id,
        title: title,
        description: description,
        hashtags: hashtags || [],
        mentions: mentions || [],
      },
    },
    {
      stepNumber: 2,
      initialValues: {
        id: id,
        category: { id: category.id, name: category.name },
        locations: locations,
      },
    },
    {
      stepNumber: 3,
      initialValues: {
        id: id,
        creatorType: content_type,
        networks: networks,
        followers: min_followers,
      },
    },
    {
      stepNumber: 4,
      initialValues: { id: id, images: images, deletedImages: [] },
    },
  ];

  const handleOpenWizard = (index) => {
    setWizard({
      stepNumber: editSteps[index].stepNumber,
      initialValues: editSteps[index].initialValues,
      isOpen: true,
    });
  };

  const handleCloseWizard = () => {
    setWizard(null);
  };

  const offerTexts = {
    fee: "Budget",
    product: "Gift or Service",
    commission: "Commission",
  };

  return (
    <>
      <div className="edit-view-container">
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <span className="edit-section-large-heading">Images</span>
            <Button
              label="Edit"
              onClick={() => {
                handleOpenWizard(3);
              }}
              theme={{
                backgroundColor: "#ffffff",
                color: "#94A4FF",
                padding: "0.1rem 1.1rem",
                fontSize: "13px",
                borderColor: "#94A4FF",
                hover: "#fff",
              }}
            />
          </div>
          <div className="campaign-detail-section mt-2">
            <div className="image-continer">
              {campaign.images.slice(0, 3).map((image, index) => (
                <>
                  <img
                    key={image.id}
                    src={image.preview}
                    alt={`${index}`}
                    className={"header-image"}
                  />
                </>
              ))}
            </div>
          </div>
        </div>
        <div className="mt-3">
          <div className="d-flex justify-content-between align-items-center">
            <span className="edit-section-heading">Offer Incentives</span>
            <Button
              label="Edit"
              onClick={() => {}}
              theme={{
                backgroundColor: "#ffffff",
                color: "#94A4FF",
                padding: "0.1rem 1.1rem",
                fontSize: "13px",
                borderColor: "#94A4FF",
                hover: "#fff",
                disabledBackgroud: "#fff",
              }}
              isDisabled={true}
            />
          </div>
          <div className="campaign-detail-section mt-2">
            <div className="description-text-heading">Content Type :</div>
            <div className="description-text">
              {campaign.content_type === "ugc"
                ? "User Generated Content"
                : "Influencer Marketing"}
            </div>

            {campaign.offers.map((offer, index) => {
              return (
                <div className="mt-2" key={`offer-${index}`}>
                  <div className="description-text-heading">
                    {offerTexts[offer.incentive]}
                  </div>
                  <div className="description-text">{offer.description}</div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="mt-3">
          <div className="d-flex justify-content-between align-items-center">
            <span className="edit-section-heading">Campaign Detail</span>
            <Button
              label="Edit"
              onClick={() => {
                handleOpenWizard(0);
              }}
              theme={{
                backgroundColor: "#ffffff",
                color: "#94A4FF",
                padding: "0.1rem 1.1rem",
                fontSize: "13px",
                borderColor: "#94A4FF",
                hover: "#fff",
              }}
            />
          </div>
          <div className="campaign-detail-section mt-2">
            <div className="description-text-heading">Title :</div>
            <div className="description-text">{campaign.title}</div>
            <div className="description-text-heading mt-3">
              Describe Your Offer:
            </div>
            <div className="description-text">
              <div dangerouslySetInnerHTML={{ __html: campaign.description }} />
            </div>
            <div className="mb-3">
              <div className="description-text-heading">Tags:</div>
              <div className="mt-1">
                {campaign.hashtags.map((tag, index) => {
                  return (
                    <span className="tags me-2" key={`tag-${index}`}>
                      {tag}
                    </span>
                  );
                })}
              </div>
            </div>

            <div>
              <div className="description-text-heading">Mentions:</div>
              <div className="mt-1">
                {campaign.mentions.map((mention, index) => {
                  return (
                    <span className="tags me-2" key={`mention-${index}`}>
                      {mention}
                    </span>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3">
          <div className="d-flex justify-content-between align-items-center">
            <span className="edit-section-heading">Category & Location</span>
            <Button
              label="Edit"
              onClick={() => {
                handleOpenWizard(1);
              }}
              theme={{
                backgroundColor: "#ffffff",
                color: "#94A4FF",
                padding: "0.1rem 1.1rem",
                fontSize: "13px",
                borderColor: "#94A4FF",
                hover: "#fff",
              }}
            />
          </div>
          <div className="campaign-detail-section mt-2">
            <div className="description-text-heading">Category :</div>
            <div className="description-text">{category.name}</div>

            <div className="description-text-heading">Location:</div>
            {locations.map((location, index) => {
              return (
                <div key={`location-${index}`} className="description-text">
                  {location.name}
                </div>
              );
            })}
          </div>
        </div>

        <div className="mt-3">
          <div className="d-flex justify-content-between align-items-center">
            <span className="edit-section-heading">Network & Followers</span>
            <Button
              label="Edit"
              onClick={() => {
                handleOpenWizard(2);
              }}
              theme={{
                backgroundColor: "#ffffff",
                color: "#94A4FF",
                padding: "0.2rem 1.2rem",
                fontSize: "13px",
                borderColor: "#94A4FF",
                hover: "#fff",
              }}
            />
          </div>
          <div className="campaign-detail-section mt-2">
            <div className="description-text-heading">
              {content_type === "ugc" ? "Networks" : "Platforms"}
            </div>
            {networks.map((network, index) => {
              return (
                <div key={`network-${index}`} className="description-text">
                  {network.name}
                </div>
              );
            })}

            <div className="description-text-heading">Minimum Followers:</div>
            <div className="description-text">{min_followers}</div>
          </div>
        </div>
      </div>

      {wizard && (
        <Wizard
          wizardIsOpen={wizard.isOpen}
          closeWizard={handleCloseWizard}
          stepNumber={wizard.stepNumber}
          initialValues={wizard.initialValues}
          editMode
          setCampaign={setCampaign}
        />
      )}
    </>
  );
};

export default CampaignEditView;
