//React imports
import React from "react"
//Custom styles
import "./styles.scss"

const NumberInput = ({ label, value, handleChange }) => {
  const preventScroll = (e) => {
    e.target.blur()
  }

  return (
    <div className="campaignFormNumberInput">
      <div className="label">{label}</div>
      <input type="number" value={value || ""} onChange={handleChange} placeholder="Enter a valid number" className="numberInput" min="1" onWheel={preventScroll} />
    </div>
  )
}

export default NumberInput
