import React, { useState, useEffect, useRef } from "react"
import Header from "../../../components/Common/Header"
import { useSelector } from "react-redux"
import Board from "@asseinfo/react-kanban"
import BoardCard from "../../../components/Boards/Card"
import BoardColumn from "../../../components/Boards/Column"
import BoardFormModal from "../../../components/Boards/FormModal"
import BoardWarningModal from "../../../components/Boards/WarningModal"
import TagsModal from "../../../components/FindInfluencers/TagsModal"
import useBoardsService from "../../../services/useBoardsService"
import UpgradePopup from "../../../components/Boards/UpgradePopup"
import "@asseinfo/react-kanban/dist/styles.css"
import Tooltip from "@mui/material/Tooltip"
import EmptyView from "../../../components/FindInfluencers/EmptyView"
import { Button, DropdownMenu } from "../../../components/Common/CommonElements"
import { engagementOptions, numberRange } from "../../../constants"
import { SelectFilter, RangeFilter } from "../../../components/FindInfluencers/Filters"
import MenuItem from "@mui/material/MenuItem"
import { Loader } from "../../../components/Common/Loaders"
import notify from "../../../components/Common/Toast"
import "./styles.scss"
import SearchIcon from "../../../assets/images/search.svg"
import EditIcon from "../../../assets/images/edit.svg"
import RemoveIcon from "../../../assets/images/remove.svg"
import BoardsTemplateImage from "../../../assets/images/boards-template.svg"

const Boards = ({ openDetails }) => {
  const boardsService = useBoardsService()

  const formModalRef = useRef()
  const formModal = formModalRef.current
  const warningModalRef = useRef()
  const warningModal = warningModalRef.current
  const tagsModalRef = useRef()
  const tagsModal = tagsModalRef.current

  const [boards, setBoards] = useState(null)
  const [currentBoard, setCurrentBoard] = useState(null)
  const [currentBoardId, setCurrentBoardId] = useState(null)
  const [filters, setFilters] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [boardKey, setBoardKey] = useState(0)
  const [filtersAreEmpty, setFiltersAreEmpty] = useState(true)

  const debounce = useRef(null)

  const currentUser = useSelector((state) => state.auth?.user)
  const credits = useSelector((state) => state.auth?.credits)

  const boardIsEmpty = currentBoard?.columns.every((column) => !column.cards.flatMap((card) => card).length)

  useEffect(() => {
    fetchBoards()
  }, [])

  const fetchCurrentBoard = () => {
    setIsLoading(true)
    if (currentBoardId) {
      boardsService
        .show(currentBoardId, filters)
        .then((data) => {
          if (data) {
            setCurrentBoard(data)
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  useEffect(() => {
    fetchCurrentBoard()
  }, [currentBoardId])

  useEffect(() => {
    const isEmpty = (value) => value === null || value === "" || (Array.isArray(value) && value.length === 0)
    setFiltersAreEmpty(Object.values(filters).every(isEmpty))
  }, [filters])

  useEffect(() => {
    !filtersAreEmpty && fetchCurrentBoard()
  }, [filters, filtersAreEmpty])

  useEffect(() => {
    setBoardKey((prev) => prev + 1)
  }, [currentBoard])

  const fetchBoards = () => {
    boardsService.index().then((data) => {
      if (data) {
        setBoards(data)
        setCurrentBoardId(data[0]?.id)
      }
    })
  }

  const updatePositions = (board) => {
    setCurrentBoard(board)
    boardsService.updatePositions(board.id, board)
  }

  const handleCardClick = (influencer) => {
    if (influencer.saved !== false || credits >= 5) {
      openDetails(influencer.id)
    } else {
      notify.error("Not enough credits to perform this action, please upgrade your plan")
    }
  }

  const handleEditColumn = (id, name) => {
    formModal?.openModal(id, name, editColumn, "Edit Column")
  }

  const handleRemoveColumn = (id) => {
    warningModal?.openModal(id, removeColumn, "Column")
  }

  const handleAddTag = (id) => {
    tagsModal?.openModal(id, addTag)
  }

  const createBoard = ({ name }) => {
    formModal?.setLoading(true)
    boardsService.createBoard(name).then((data) => {
      if (data) {
        setBoards((prev) => [...prev, data])
        setCurrentBoardId(data.id)
        formModal?.setLoading(false)
        formModal?.closeModal()
      }
    })
  }

  const editBoard = ({ id, name }) => {
    formModal?.setLoading(true)
    boardsService.editBoard(id, name).then((data) => {
      if (data) {
        setBoards((prev) => prev.map((board) => (board.id === id ? { ...board, name: name } : board)))
        if (currentBoardId === id) {
          setCurrentBoard((prev) => ({
            ...prev,
            name: name,
          }))
        }
        formModal?.setLoading(false)
        formModal?.closeModal()
      }
    })
  }

  const removeBoard = (id) => {
    warningModal?.setLoading(true)
    boardsService.removeBoard(id).then((data) => {
      if (data) {
        fetchBoards()
        warningModal?.setLoading(false)
        warningModal?.closeModal()
      }
    })
  }

  const createColumn = ({ name }) => {
    formModal?.setLoading(true)
    boardsService.createColumn(name, currentBoard.id).then((data) => {
      if (data) {
        setCurrentBoard((prev) => ({
          ...prev,
          columns: [...prev.columns, { id: data.id, name: name, cards: [] }],
        }))
        formModal?.setLoading(false)
        formModal?.closeModal()
      }
    })
  }

  const editColumn = ({ id, name }) => {
    formModal?.setLoading(true)
    boardsService.editColumn(id, name).then((data) => {
      if (data) {
        setCurrentBoard((prev) => ({
          ...prev,
          columns: prev.columns.map((column) => (column.id === id ? { ...column, name: name } : column)),
        }))
        formModal?.setLoading(false)
        formModal?.closeModal()
      }
    })
  }

  const removeColumn = (id) => {
    warningModal?.setLoading(true)
    boardsService.removeColumn(id).then((data) => {
      if (data) {
        setCurrentBoard((prev) => ({
          ...prev,
          columns: prev.columns.filter((column) => column.id !== id),
        }))
        warningModal?.setLoading(false)
        warningModal?.closeModal()
      }
    })
  }

  const removeCard = (id) => {
    boardsService.removeCard(id).then((data) => {
      if (data) {
        setCurrentBoard((prev) => ({
          ...prev,
          columns: prev.columns.map((column) => ({
            ...column,
            cards: column.cards.filter((card) => card.id !== id),
          })),
        }))
      }
    })
  }

  const addTag = ({ id, content, color }) => {
    tagsModal?.setLoading(true)
    boardsService.createTag(id, content, color).then((data) => {
      if (data) {
        setCurrentBoard((prev) => ({
          ...prev,
          columns: prev.columns.map((column) => ({
            ...column,
            cards: column.cards.map((card) => {
              if (card.id === id) {
                return {
                  ...card,
                  tags: [...card.tags, { id: id, name: content, color: color }],
                }
              }
              return card
            }),
          })),
        }))
        tagsModal?.setLoading(false)
        tagsModal?.closeModal()
      }
    })
  }

  const removeTag = (id) => {
    boardsService.removeTag(id).then((data) => {
      if (data) {
        setCurrentBoard((prev) => ({
          ...prev,
          columns: prev.columns.map((column) => ({
            ...column,
            cards: column.cards.map((card) => ({
              ...card,
              tags: card.tags.filter((tag) => tag.id !== id),
            })),
          })),
        }))
      }
    })
  }

  const handleSearch = (event) => {
    clearTimeout(debounce.current)
    debounce.current = setTimeout(() => {
      setFilters((prev) => ({
        ...prev,
        query: event.target.value,
      }))
    }, 100)
  }

  return (
    <section className="boards-page">
      {boards?.length > 0 ? (
        <>
          <div className="boards-select-container">
            <DropdownMenu label={currentBoard?.name} theme={{ backgroundColor: "#FFFFFF00", fontSize: "24px", border: "none", borderRadius: "unset" }}>
              {boards?.map((board) => (
                <MenuItem onClick={() => setCurrentBoardId(board.id)}>
                  <div className="mui-dropdown-menu-item-container mui-dropdown-menu-item-container-large">
                    <div className="dropdown-item-text">{board.name}</div>
                    <div className="button-actions">
                      <Button
                        type="button"
                        icon={<img src={EditIcon} alt="" />}
                        onClick={() => formModal?.openModal(board.id, board.name, editBoard, "Edit Board")}
                        theme={{ backgroundColor: "#ffffff", padding: "0" }}
                      />
                      <Button
                        type="button"
                        icon={<img src={RemoveIcon} alt="" />}
                        onClick={() => warningModal?.openModal(board.id, removeBoard, "Board")}
                        theme={{ backgroundColor: "#ffffff", padding: "0" }}
                      />
                    </div>
                  </div>
                </MenuItem>
              ))}
            </DropdownMenu>
            <Loader color="#000000" visible={isLoading} />
          </div>
          <div className="actions-bar">
            <div className="searchbar-container">
              <div className="search-icon-container">
                <img src={SearchIcon} className="icon" alt="" />
              </div>
              <input type="text" placeholder="Search..." className="searchbar" onChange={(e) => handleSearch(e)} />
            </div>
            <DropdownMenu label="Followers" theme={{ backgroundColor: "#ffffff", fontSize: "16px", border: "1px solid #e2e8f0", borderRadius: "25px" }}>
              <RangeFilter name="followers" fromOptions={numberRange.from} toOptions={numberRange.to} state={filters} setState={setFilters} />
            </DropdownMenu>
            <DropdownMenu label="Engagement Rate" theme={{ backgroundColor: "#ffffff", fontSize: "16px", border: "1px solid #e2e8f0", borderRadius: "25px" }}>
              <SelectFilter name="engagement" options={engagementOptions} state={filters} setState={setFilters} placeholder="Any" />
            </DropdownMenu>
            <div style={{ marginLeft: "auto" }}>
              <Button
                type="button"
                label="Add New Column"
                onClick={() => formModal?.openModal(currentBoard.id, null, createColumn, "Create Column")}
                theme={{ backgroundColor: "#ffffff", borderColor: "#000000", color: "black", padding: "0.8rem 2.2rem", fontSize: "14px" }}
              />
            </div>
            {boards?.length >= currentUser?.permissions.boards ? (
              <Tooltip title={<UpgradePopup content="You have exceed your board limit" />} placement="top" arrow className="upgrade-popup-tooltip">
                <div>
                  <Button type="button" label="Add New Board" isDisabled={true} theme={{ backgroundColor: "#000000", color: "white", padding: "0.8rem 2.2rem", fontSize: "14px" }} />
                </div>
              </Tooltip>
            ) : (
              <Button
                type="button"
                label="Add New Board"
                onClick={() => formModal?.openModal(null, null, createBoard, "Add New Board")}
                theme={{ backgroundColor: "#000000", color: "white", padding: "0.8rem 2.2rem", fontSize: "14px" }}
              />
            )}
          </div>
          <div className="react-board-container">
            {!isLoading && !filtersAreEmpty && boardIsEmpty ? (
              <EmptyView />
            ) : currentBoard ? (
              <Board
                allowRemoveCard={true}
                key={boardKey}
                renderCard={(card) => (
                  <BoardCard
                    key={card.id}
                    id={card.id}
                    data={card}
                    handleCardClick={handleCardClick}
                    removeTag={removeTag}
                    removeCard={removeCard}
                    handleAddTag={handleAddTag}
                    lockFeatures={currentUser?.lock_features}
                  />
                )}
                renderColumnHeader={(column) => <BoardColumn key={column.id} id={column.id} name={column.name} handleEditColumn={handleEditColumn} handleRemoveColumn={handleRemoveColumn} />}
                initialBoard={currentBoard}
                onColumnDragEnd={updatePositions}
                onCardDragEnd={updatePositions}
              />
            ) : null}
          </div>
        </>
      ) : (
        <div className="no-boards-display">
          <div className="heading"> Your Boards</div>
          <div className="image-container">
            <img src={BoardsTemplateImage} alt="" className="image" />
          </div>
          <div className="description">
            Start managing your content efficiently. <br /> Create your first board now
          </div>
          <Button
            type="button"
            label="Add New Board"
            onClick={() => formModal?.openModal(null, null, createBoard, "Add New Board")}
            theme={{ backgroundColor: "#000000", color: "white", padding: "0.8rem 2.2rem", fontSize: "14px" }}
          />
        </div>
      )}
      <BoardFormModal ref={formModalRef} />
      <BoardWarningModal ref={warningModalRef} />
      <TagsModal ref={tagsModalRef} />
    </section>
  )
}

export default Boards
