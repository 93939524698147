//React imports
import React from "react"
import Radio from "@mui/material/Radio"
//Custom styles
import "./styles.scss"
//Assets
import { RadioSelected, CheckboxUncheckedIcon } from "../../../../../../assets/images/Common"

const RadioButton = ({ checked, disabled }) => {
  const customStyles = {
    color: "#BDBDBD",
    "&.Mui-checked": {
      color: "#94A4FF",
    },
    "& .MuiSvgIcon-root": {
      fontSize: 26,
    },
  }

  return (
    <div className="campaignFormRadioButton">
      <Radio icon={<img src={CheckboxUncheckedIcon} alt="icon" />} checkedIcon={<img src={RadioSelected} alt="icon" />} sx={customStyles} checked={checked} disabled={disabled} />
    </div>
  )
}

export default RadioButton
