//React imports
import React from "react"
//Custom styles
import "./styles.scss"
//Assets
import { CrossIcon } from "../../../../../../assets/images/Common"

const ImageUpload = ({ image, handleRemove }) => {
  return (
    <div className="campaignFormImageUpload">
      <img src={image} alt="upload" className="image" />
      <img src={CrossIcon} alt="remove" className="crossIcon" onClick={handleRemove} />
    </div>
  )
}

export default ImageUpload
