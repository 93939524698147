// React import
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

// Assets
import CrossIcon from "../../../assets/images/cross-icon-white.svg";

//style
import "./style.scss";

const CampaignImagesViewerModal = ({
  openCampaignImagesModal,
  setOpenCampaignImagesModal,
  images,
}) => {
  // helper constants
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "96%",
    height: "80%",
    padding: "30px",
    margin: "auto",
    borderRadius: "15px",
    backgroundColor: "#FFFFFF",
    border: "none !important",
  };

  return (
    <>
      <Modal
        className="stripe-payment-modal"
        open={openCampaignImagesModal}
        onClose={() => {
          setOpenCampaignImagesModal(false);
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openCampaignImagesModal}>
          <Box sx={style}>
            <div className="d-flex justify-content-between">
              <div>
                <span className="fs-6 mb-3">
                  <b>Images</b>
                </span>
              </div>
              <img
                src={CrossIcon}
                className="cursor-pointer"
                alt=""
                onClick={() => setOpenCampaignImagesModal(false)}
              />
            </div>

            <div className="image-gallery mt-3">
              {images.map((image, index) => (
                <img key={index} src={image.preview} alt={`image-${index}`} />
              ))}
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default CampaignImagesViewerModal;
