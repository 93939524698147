// React imports
import React from "react"
// Custom styles
import "./styles.scss"

const TextInput = ({ label, value, setValue, limit }) => {
  const handleChange = (event) => {
    const newValue = event.target.value
    if (newValue.length <= limit) {
      setValue(newValue)
    }
  }

  return (
    <div className="campaignFormTextInputContainer">
      <div className="label">{label}</div>
      <div className="campaignFormTextInput">
        <input type="text" value={value} onChange={handleChange} placeholder="Enter..." className="textInput" />
        <div className="characterCount">
          {value.length}/{limit}
        </div>
      </div>
    </div>
  )
}

export default TextInput
