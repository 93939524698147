import React, { useState, useEffect } from "react"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import PaymentSetupForm from "../../../components/Account/Payment/PaymentMethod/paymentSetupForm"
import usePaymentMethodService from "../../../services/usePaymentMethodService"
import { FormControl } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import ChargeImage from "../../../assets/images/charge.png"
import HiveInfluence from "../../../assets/images/hive_influence.png"
import useAuthService from "../../../services/useAuthService"
import DefaultProfilePicture from "../../../assets/images/default-profile-picture.svg"
import AlertIcon from "../../../assets/images/payment_alert.svg"
import { useSelector } from "react-redux"

import "./style.scss"
const PaymentProfile = () => {
  const [paymentMethodModalOpen, setPaymentMethodModalOpen] = useState(true)
  const [clientSecret, setClientSecret] = useState("")
  const [publishableKey, setPublishableKey] = useState("")
  const paymentMethodHookService = usePaymentMethodService()
  const [loading, setLoading] = useState(true)
  const stripePromise = publishableKey ? loadStripe(publishableKey) : ""
  const navigate = useNavigate()
  const authService = useAuthService()
  const currentUser = useSelector((state) => state.auth?.user)

  const handleLogout = async () => {
    authService.logout().then((data) => {
      navigate("/")
    })
  }

  useEffect(() => {
    if (!paymentMethodModalOpen) {
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    }

    if (currentUser.payment_required) {
      paymentMethodHookService
        .setupIntent()
        .then((result) => {
          if (result) {
            setLoading(false)
            setPublishableKey(result.publishable_key)
            setClientSecret(result.client_secret)
          }
        })
        .catch((error) => {
          console.log(error.response.data.error)
        })
    } else {
      navigate(`/brand`)
    }
  }, [paymentMethodModalOpen])

  return (
    <div style={{ padding: "10px 40px" }}>
      <div class="dropdown d-flex justify-content-end mb-3 cursor-pointer">
        <div className="dropdown-toggle d-flex align-items-center" id="avatarDropdownMenu" data-bs-toggle="dropdown" aria-expanded="false">
          <img src={DefaultProfilePicture} className="avatar" alt="" style={{ width: "30px", marginRight: "10px" }} />
          <div className="d-flex flex-column">
            <span className="font-13"> {currentUser?.name}</span>
            <span className="email-header">{currentUser?.email}</span>
          </div>
        </div>
        <ul class="dropdown-menu" aria-labelledby="avatarDropdownMenu">
          <li>
            <div className=" dropdown-item text-danger" onClick={handleLogout}>
              Log out
            </div>
          </li>
        </ul>
      </div>

      <div className="row payment-main-section gx-0">
        <div className="col-12 col-md-6">
          <div className="payment-side-section ">
            <div className="d-flex flex-column align-items-center">
              <div className="main-heading text-center">Relax, your wallet stays untouched—we’re just making sure you’re not a robot in disguise.</div>
              <div>
                <img className="charge-logo" src={ChargeImage} />
              </div>
              <div className="credit-text text-center mt-4">
                Get <span className="credit-text-color">40</span> search credits for free
              </div>
              <div className="hive-influence-logo">
                <img src={HiveInfluence} />
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6 p-4" style={{ backgroundImage: "linear-gradient(#fee3ff, #f3f6fe)" }}>
          {!loading && (
            <Elements
              stripe={stripePromise}
              options={{
                clientSecret: clientSecret,
                appearance: {
                  theme: "flat",
                  variables: {
                    fontFamily: "Open Sans, system-ui, sans-serif",
                  },
                },
              }}>
              <div
                className="w-100 p-5 mt-4"
                style={{
                  paddingRight: "20px",
                  backgroundColor: "white",
                  borderRadius: "15px",
                }}>
                <div className="mb-4">
                  {currentUser?.payment_method_error_message && (
                    <div className="payment-alert-section text-center mb-3">
                      <img className="alert-icon mx-2" src={AlertIcon} />
                      {currentUser?.payment_method_error_message}
                    </div>
                  )}
                  <h5>Payment</h5>
                  <div className="font-13">Don’t worry, we won’t ninja-charge you after the trial! Decide at your leisure; if you love us, pick a plan and we’re good to go!</div>
                </div>
                <label style={{ fontSize: "14px", marginBottom: "3px" }}>Card Holder Name</label>
                <FormControl type="text" placeholder="John Smith" />
                <PaymentSetupForm setStripeModalOpen={setPaymentMethodModalOpen} redirectionUrl={window.location.href} />
              </div>
            </Elements>
          )}
        </div>
      </div>
    </div>
  )
}

export default PaymentProfile
