// React Import
import React, { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";

// style
import "./style.scss";

const RichTextEditor = ({ content, setContentData }) => {
  // Use States
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  //helper constant
  let contentState = "";

  // Helpers
  const toolbarOptions = {
    options: [
      "inline",
      "fontSize",
      "blockType",
      "list",
      "textAlign",
      "link",
      "image",
    ],
    inline: {
      options: ["bold", "italic", "underline"],
    },
    list: {
      options: ["unordered", "ordered"],
    },
    blockType: {
      inDropdown: false,
      options: ["Normal", "H1", "H2"],
    },
    image: {
      urlEnabled: true, // Enable the option to add images via URL
      uploadEnabled: true, // Enable the option to upload images
      uploadCallback: (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve({ data: { link: reader.result, alt: "Default Alt Text" } });
          };
          reader.onerror = reject;
          reader.readAsDataURL(file); // Convert file to a base64-encoded URL
        });
      },
      previewImage: true,
    },
  };
  // Use Effects
  useEffect(() => {
    if (content) {
      const blocksFromHTML = convertFromHTML(content);
      contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, []);

  // handlers
  const handleEditorStateChange = (state) => {
    setEditorState(state);
    const contentState = editorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);
    setContentData(draftToHtml(rawContent));
  };
  return (
    <div className="editor-container">
      <Editor
        editorState={editorState}
        toolbarClassName="toolbar-class"
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        onEditorStateChange={handleEditorStateChange}
        toolbar={toolbarOptions}
        toolbarPosition="bottom"
        placeholder="Write your message here..."
      />
    </div>
  );
};

export default RichTextEditor;
