import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { Box } from "@mui/material";
import Select from "react-select";
import Form from "react-bootstrap/Form";

// custom components
import { Button } from "../../../components/Common/CommonElements";
import RichTextEditor from "../../Common/RichTextEditor";

// style
import "./style.scss";

// Services
import useMailboxService from "../../../services/useMailboxService";
import useEmailTemplatesService from "../../../services/useEmailTemplatesService";

const EmailCompose = ({ sentToDetails, setOpenConnectModal }) => {
  // Redux constants
  const currentUser = useSelector((state) => state.auth?.user);

  // Helpers
  const emailOptions = currentUser.connected_mailbox_grants.map((account) => ({
    label: account.email,
    value: account.slug,
  }));

  // Service Constants
  const mailboxHookService = useMailboxService();
  const emailTemplateService = useEmailTemplatesService();

  // Use States
  const [emailData, setEmailData] = useState({
    to_email: sentToDetails.email,
    to_name: sentToDetails.name,
    influencer_id: sentToDetails.influencerId,
    from: emailOptions[0],
    subject: "",
    body: "",
  });
  const [templatesData, setTemplatesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTemplateIndex, setSelectedTemplateIndex] = useState("");
  const [selectedTemplateOption, setSelectedTemplateOption] = useState("");

  // Use Effects
  useEffect(() => {
    emailTemplateService.list("").then((data) => {
      if (data) {
        setTemplatesData(data);
        setLoading(false);
      }
    });
  }, []);

  // Helpers
  const templateOptions = templatesData.map((temp, index) => ({
    label: temp.name,
    value: index,
  }));

  // Handlers
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmailData({ ...emailData, [name]: value });
  };

  const handleSelectChange = (option) => {
    setEmailData({ ...emailData, ["from"]: option });
  };

  const handleTemplateSelectChange = (option) => {
    setSelectedTemplateIndex(option.value);
    setSelectedTemplateOption(option);
    setEmailData({
      ...emailData,
      ["subject"]: templatesData[option.value].subject,
    });
  };

  const handleSendMessage = (e) => {
    e.preventDefault();
    document.getElementById("sendEmailSpinner").classList.remove("d-none");
    mailboxHookService
      .sendEmail(emailData)
      .then((result) => {
        if (result) {
          setOpenConnectModal(false);
        }
      })
      .catch((error) => {
        console.log(error.response.data.error);
      });
  };

  const setContentData = (parsedContent) => {
    setEmailData({ ...emailData, ["body"]: parsedContent });
  };

  // Helpers
  const customStyles = {
    control: (provided) => ({
      ...provided,
      padding: "5px",
      paddingLeft: "35px",
      margin: "1px",
      borderRadius: "20px",
      marginBottom: "10px",
      marginTop: "15px",
      fontSize: "15px",
      fontWeight: "400",
      color: "#000000",
      "&:hover": {
        cursor: "pointer",
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 200,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#C4CCFF"
        : state.isFocused
        ? "lightgray"
        : "white",
      color: "black",
      "&:hover": {
        cursor: "pointer",
      },
    }),
  };

  const smallSelectCustomStyles = {
    control: (provided) => ({
      ...provided,
      margin: "1px",
      border: "1px solid #E2E8F0",
      borderRadius: "20px",
      marginBottom: "10px",
      marginTop: "15px",
      fontSize: "14px",
      fontWeight: "700",
      color: "#000000",
      backgroundColor: "#F2F2F2",
      width: "150px",
      "&:hover": {
        cursor: "pointer",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#C4CCFF"
        : state.isFocused
        ? "lightgray"
        : "white",
      color: "black",
      width: "100%",
      "&:hover": {
        cursor: "pointer",
      },
    }),
    menu: (provided) => ({
      ...provided,
      width: "auto", // Makes the menu width flexible
      minWidth: "150px", // Ensures a minimum width based on control
      whiteSpace: "nowrap", // Prevents text from wrapping
      zIndex: 200,
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#000000",
      fontWeight: "500",
    }),
  };

  return (
    <>
      {!loading && (
        <Box>
          <form>
            <div className="position-relative">
              <span className="from-label">From: </span>
              <Select
                options={emailOptions}
                closeMenuOnSelect={true}
                onChange={handleSelectChange}
                value={emailData.from}
                name="from"
                styles={customStyles}
              />
            </div>

            <Box sx={{ marginBottom: "20px" }}>
              <div className="position-relative">
                <span className="from-label">To: </span>
                <Form.Control
                  type="text"
                  defaultValue={emailData.to_email}
                  className="to-input pl-35"
                  disabled
                  readOnly
                />
              </div>
            </Box>

            <div className="d-flex gap-2 px-1">
              <Select
                options={templateOptions}
                closeMenuOnSelect={true}
                onChange={handleTemplateSelectChange}
                value={selectedTemplateOption}
                styles={smallSelectCustomStyles}
                placeholder="Templates"
              />
              {/* <Select
                options={[]}
                closeMenuOnSelect={true}
                onChange={handleSelectChange}
                value={""}
                name=""
                placeholder="Variables"
                styles={smallSelectCustomStyles}
              />
              <Select
                options={[]}
                closeMenuOnSelect={true}
                onChange={handleSelectChange}
                value={""}
                name="from"
                placeholder="Campaigns"
                styles={smallSelectCustomStyles}
              /> */}
            </div>

            <Box sx={{ marginBottom: "10px", marginTop: "15px" }}>
              <Form.Control
                type="text"
                className="to-input"
                name="subject"
                value={emailData.subject}
                onChange={handleChange}
                placeholder="Subject"
                required
              />
            </Box>
            <Box sx={{ marginBottom: "20px" }}>
              <RichTextEditor
                key={selectedTemplateIndex}
                content={templatesData[selectedTemplateIndex]?.content}
                setContentData={setContentData}
              />
            </Box>
            <div className="d-flex justify-content-end position-relative gap-3">
              <Button
                label="Cancel"
                onClick={() => {
                  setOpenConnectModal(false);
                }}
                theme={{
                  backgroundColor: "#f6f7fb",
                  color: "#000",
                  padding: "0.5rem 2.2rem",
                  fontSize: "14px",
                }}
              />
              <Spinner
                animation="border"
                variant="light"
                size="sm"
                className="send-email-spinner-position d-none"
                id="sendEmailSpinner"
              />
              <Button
                type="submit"
                label="Send Email"
                onClick={handleSendMessage}
                theme={{
                  backgroundColor: "#94A3FF",
                  color: "#ffffff",
                  padding: "0.5rem 1.6rem",
                  fontSize: "14px",
                }}
              />
            </div>
          </form>
        </Box>
      )}
      {loading && (
        <div className={"email-compose-lading-container"}>
          <Spinner
            animation="border"
            style={{ color: "#94A3FF", width: "70px", height: "70px" }}
          />
        </div>
      )}
    </>
  );
};

export default EmailCompose;
