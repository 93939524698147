//React imports
import React from "react"
//Custom styles
import "./styles.scss"
//Assets
import { LocationSmallIcon } from "../../../../../../assets/images/Campaign"

const LocationCard = ({ location, handleRemove }) => {
  return (
    <div className="campaignFormLocationCard">
      <div className="icon-container">
        <img src={LocationSmallIcon} alt="location" className="icon" />
      </div>
      <div className="name">{location.name}</div>
      <div className="removeButton" onClick={() => handleRemove(location)}>
        Remove
      </div>
    </div>
  )
}

export default LocationCard
