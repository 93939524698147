import React, { useState, useEffect } from "react";
import { Img } from "react-image";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import useAuthService from "../../../services/useAuthService";
import Skeleton from "@mui/material/Skeleton";
import { useMediaQuery } from "react-responsive";
import "./styles.scss";
import Logo from "../../../assets/images/logo.svg";
import DefaultProfilePicture from "../../../assets/images/default-profile-picture.svg";
import ArrowDown from "../../../assets/images/arrow-down.svg";
import BlackDropdownIcon from "../../../assets/images/icons/black_dropdown.svg";
const Header = () => {
  const navigate = useNavigate();
  const authService = useAuthService();
  const location = useLocation();
  const currentUser = useSelector((state) => state.auth?.user);
  const credits = useSelector((state) => state.auth?.credits);
  const [avatarImage, setAvatarImage] = useState(
    currentUser?.profile_picture_url || DefaultProfilePicture
  );

  const navLinks = [
    { path: "/brand/find-influencers", label: "Find Influencers" },
    { path: "/brand/manage-influencers", label: "Manage Influencers" },
    { path: "/brand/boards", label: "Boards" },
  ];

  const isSmallScreen = useMediaQuery({ query: "(max-width: 1230px)" });

  useEffect(() => {
    setAvatarImage(currentUser?.profile_picture_url || DefaultProfilePicture);
  }, [currentUser]);

  const handleLogout = async () => {
    authService.logout().then(() => {
      navigate("/login");
    });
  };

  const handleNavigation = (path) => {
    if (location.pathname !== path) {
      navigate(path, { replace: true });
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <>
      {isSmallScreen && (
        <div className="mobile-warning-banner">
          <div className="heading">
            Sorry, we are not attractive on this screen size !!! <br />
            Please use the application on a larger screen for a better
            experience
          </div>
        </div>
      )}
      <header>
        <div className="header-content">
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <div
                className="logo-container"
                onClick={() => handleNavigation("/brand/find-influencers")}
              >
                <img src={Logo} className="logo" alt="logo" />
              </div>
              <div className="nav-container">
                {navLinks.map((item) => (
                  <button
                    key={item.path}
                    className={`nav-link ${
                      location.pathname === item.path ? "active" : ""
                    }`}
                    onClick={() => handleNavigation(item.path)}
                  >
                    {item.label}
                  </button>
                ))}

                <div class="dropdown outreach-dropdown">
                  <a
                    className="cursor-pointer"
                    id="dropdownMenuLink"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Outreach Emails
                    <img
                      className="ms-1"
                      src={BlackDropdownIcon}
                      alt="dropdown icon"
                    />
                  </a>

                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <li>
                      <a
                        class="dropdown-item"
                        onClick={() => navigate("/brand/outreach-emails")}
                      >
                        Activities
                      </a>
                    </li>
                    <li className="mt-2">
                      <a
                        class="dropdown-item"
                        onClick={() => navigate("/brand/email-templates")}
                      >
                        Templates
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="ms-4 d-flex align-items-center">
                  <span
                    className="campaign-btn"
                    onClick={() => navigate("/brand/campaigns")}
                  >
                    Campaigns
                  </span>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="results-container">
                <div className="result mx-2">
                  <span className="heading">Remaining credits: </span>
                  <span className="value">{credits}</span>
                </div>
                <button
                  className="btn ms-2"
                  onClick={() => navigate("/brand/plans")}
                >
                  Upgrade
                </button>
              </div>
              <div className="d-flex flex-row justify-content-start align-items-center">
                <div className="avatar-container">
                  <Img
                    src={avatarImage}
                    className="avatar"
                    loader={
                      <Skeleton variant="rounded" width={150} height={150} />
                    }
                  />
                </div>
                <div className="profile-info">
                  <div className="name">{currentUser?.name}</div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="email">{currentUser?.email}</div>
                    <button
                      type="button"
                      className="dropdown-toggle"
                      data-bs-toggle="dropdown"
                    >
                      <div className="icon-container">
                        <img src={ArrowDown} className="icon" alt="" />
                      </div>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-end">
                      <li>
                        <a className="dropdown-item mb-1" href="/brand/account">
                          Account
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item mb-1"
                          href="/brand/account?activeTab=billing"
                        >
                          Billing
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item mb-1"
                          href="/brand/account?activeTab=payment"
                        >
                          Payment Methods
                        </a>
                      </li>
                      <li>
                        <div
                          className="dropdown-item text-danger"
                          onClick={handleLogout}
                        >
                          Log out
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
