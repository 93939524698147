// React imports
import React from "react"
import { Img } from "react-image"
import Skeleton from "@mui/material/Skeleton"
//Custom component imports
import RadioButton from "../RadioButton"
// Custom styles
import "./styles.scss"

const CategoryCard = ({ label, image, selected, onClick }) => {
  return (
    <div className="campaignFormCategoryCard" onClick={onClick}>
      <Img src={image} className="image" alt="category" loader={<Skeleton variant="rounded" width={165} height={208} />} />
      <div className="labelContainer">
        <div className="label">{label}</div>
      </div>
      <RadioButton checked={selected} />
    </div>
  )
}

export default CategoryCard
