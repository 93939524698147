//React imports
import React from "react"
//Custom component imports
import OptionCard from "../Common/OptionCard"
import NumberInput from "../Common/NumberInput"
import SelectInput from "../Common/SelectInput"
//Custom styles
import "./styles.scss"
//Custom helpers
import { budgetOptions } from "./helper"
//Assets
import { CameraIcon, CameraLargeIcon, GiftIcon, PercentageIcon } from "../../../../../assets/images/Campaign"

const Offers = ({ values: { creatorType, incentives }, setFieldValue, errors, displayErrors }) => {
  const setCreatorType = (value) => {
    setFieldValue("creatorType", value)
    setFieldValue("incentives", {
      fee: { selected: false, value: null },
      product: { selected: false, value: null, description: "delivery" },
      commission: { selected: false, value: null },
    })
    setFieldValue("networks", [])
  }

  const handleChange = (field, value) => {
    setFieldValue(field, value)
  }

  return (
    <div className="campaignFormOffersStep">
      <div className="creatorTypeSelectionContainer">
        <div className="heading">What do you offer in your collaborations?</div>
        <div className="creatorTypeSelection">
          <OptionCard
            title="Influencer Marketing"
            description="Leverage influencers audiences by having them share your brand on thier social channels."
            icon={CameraIcon}
            type="radio"
            selected={creatorType === "marketing"}
            onClick={() => setCreatorType("marketing")}
          />
          <OptionCard
            title="User Generated Content"
            description="Create high quality UGC for your brand to use across ads, websites, and social media."
            icon={CameraIcon}
            type="radio"
            selected={creatorType === "ugc"}
            onClick={() => setCreatorType("ugc")}
          />
        </div>
        {displayErrors && errors?.creatorType && <div className="errorMessage">{errors.creatorType}</div>}
      </div>
      {displayErrors && errors?.incentives && typeof errors.incentives === "string" && <div className="errorMessage">{errors.incentives}</div>}
      <div className="incentiveSelectionContainer">
        {creatorType === "marketing" && (
          <div className="incentive">
            {(() => {
              const selected = incentives.fee.selected
              const value = incentives.fee.value?.value
              return (
                <>
                  <OptionCard
                    title="Creator Fee"
                    description="Creators will specify their fee request in their proposals."
                    icon={CameraLargeIcon}
                    type="checkbox"
                    selected={selected}
                    onClick={() => handleChange("incentives.fee.selected", !selected)}
                  />
                  {selected && (
                    <div className="w-50">
                      <SelectInput label="What is your Budget In USD ?" value={value} options={budgetOptions} handleChange={(value) => handleChange("incentives.fee.value", value.value)} />
                      {displayErrors && errors?.incentives?.fee?.value && <div className="errorMessage">{errors.incentives.fee.value}</div>}
                    </div>
                  )}
                </>
              )
            })()}
          </div>
        )}
        {creatorType === "ugc" && (
          <div className="incentive">
            {(() => {
              const selected = incentives.fee.selected
              const value = incentives.fee.value
              return (
                <>
                  <OptionCard
                    title="User Generated Content"
                    description="Creators produce original content for your brand."
                    icon={CameraLargeIcon}
                    type="checkbox"
                    selected={selected}
                    onClick={() => handleChange("incentives.fee.selected", !selected)}
                  />
                  {selected && (
                    <div className="w-50">
                      <NumberInput label="What is your Budget In USD ?" value={value} handleChange={(e) => handleChange("incentives.fee.value", e.target.value)} />
                      {displayErrors && errors?.incentives?.fee?.value && <div className="errorMessage">{errors.incentives.fee.value}</div>}
                    </div>
                  )}
                </>
              )
            })()}
          </div>
        )}
        {creatorType && (
          <div className="incentive">
            {(() => {
              const selected = incentives.product.selected
              const value = incentives.product.value
              const description = incentives.product.description
              return (
                <>
                  <OptionCard
                    title="Free product or service"
                    description="Creators recieve a free product or service to showcase."
                    icon={GiftIcon}
                    type="checkbox"
                    selected={selected}
                    onClick={() => handleChange("incentives.product.selected", !selected)}
                  />
                  {selected && (
                    <>
                      <div className="w-50">
                        <NumberInput label="What is the value of the gift In USD?" value={value} handleChange={(e) => handleChange("incentives.product.value", e.target.value)} />
                        {displayErrors && errors?.incentives?.product?.value && <div className="errorMessage">{errors.incentives.product.value}</div>}
                      </div>
                      <div className="giftSelectionContainer">
                        <div className="heading">How will creators receive their free gift?</div>
                        <div className="giftOptions">
                          <div className={`giftOption ${description === "delivery" ? "giftOptionSelected" : ""}`} onClick={() => handleChange("incentives.product.description", "delivery")}>
                            Delivery (mail, email, voucher, ...)
                          </div>
                          <div className={`giftOption ${description === "visit" ? "giftOptionSelected" : ""}`} onClick={() => handleChange("incentives.product.description", "visit")}>
                            Walk-in visit
                          </div>
                        </div>
                        {displayErrors && errors?.incentives?.product?.description && <div className="errorMessage">{errors.incentives.product.description}</div>}
                      </div>
                    </>
                  )}
                </>
              )
            })()}
          </div>
        )}
        {creatorType === "marketing" && (
          <div className="incentive">
            {(() => {
              const selected = incentives.commission.selected
              const value = incentives.commission.value
              return (
                <>
                  <OptionCard
                    title="Commission on sales"
                    description="Creators earn a commission for each sale made."
                    icon={PercentageIcon}
                    type="checkbox"
                    selected={selected}
                    onClick={() => handleChange("incentives.commission.selected", !selected)}
                  />
                  {selected && (
                    <div className="w-50">
                      <NumberInput label="What % of sales will the creator get?" value={value} handleChange={(e) => handleChange("incentives.commission.value", e.target.value)} />
                      {displayErrors && errors?.incentives?.commission?.value && <div className="errorMessage">{errors.incentives.commission.value}</div>}
                    </div>
                  )}
                </>
              )
            })()}
          </div>
        )}
      </div>
    </div>
  )
}

export default Offers
