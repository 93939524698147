// React
import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"

// Custom Component
import CampaignEditView from "../../../../components/Campaigns/CampaignEditView"
import Header from "../../../../components/Common/Header"
// assets

// Services
import useCampaignService from "../../../../services/useCampaignService"

// component to render
const CampaignPublicPage = () => {
  //helper constants
  const { slug } = useParams()

  // Service Constants
  const campaignService = useCampaignService()

  // Use States
  const [campaign, setCampaign] = useState("")

  // Use Effects
  useEffect(() => {
    console.log("Edit Useeffect")
    campaignService.show(slug).then((data) => {
      if (data) {
        setCampaign(data)
      }
    })
  }, [])

  // Component to render

  return (
    <>
      <Header />
      {campaign && <CampaignEditView campaign={campaign} setCampaign={setCampaign} />}
    </>
  )
}

export default CampaignPublicPage
