import React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import Register from "./pages/Authentication/Register";
import Login from "./pages/Authentication/Login";
import ForgotPassword from "./pages/Authentication/ForgotPassword";
import ResetPassword from "./pages/Authentication/ResetPassword";
import Confirmation from "./pages/Authentication/Confirmation";
import SetRole from "./pages/Authentication/SetRole";
import BrandDashboard from "./pages/Brand/BrandDashboard";
import FindInfluencers from "./pages/Brand/FindInfluencers";
import ManageInfluencers from "./pages/Brand/ManageInfluencers";
import Boards from "./pages/Brand/Boards";
import BrandAccount from "./pages/Brand/BrandAccount";
import PaymentProfile from "./pages/Brand/PaymentProfile";
import PaymentPlans from "./pages/Brand/PaymentPlans";
import Checkout from "./pages/Brand/Checkout";
import AuthGuard from "./components/Authentication/Guards/AuthGuard";
import RoleGuard from "./components/Authentication/Guards/RoleGuard";
import ExternalRedirect from "./components/Authentication/ExternalRedirect";
import MailBoxCallback from "./pages/Brand/MailBoxCallback";
import OutreachEmails from "./pages/Brand/OutreachEmails";
import EmailTemplates from "./pages/Brand/EmailTemplates";
import CampaignsListing from "./pages/Brand/Campaigns/Listing";
import CampaignEdit from "./pages/Brand/Campaigns/EditCampaign";
import CampaignPublicPage from "./pages/PublicPages/CampaignPublicPage";
import ShowCampaign from "./pages/Brand/Campaigns/ShowCampaign";
export const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to={"/login"} />,
  },
  {
    path: "/campaign/:slug",
    element: <CampaignPublicPage />,
  },
  {
    path: "",
    element: <AuthGuard />,
    children: [
      {
        path: "/register",
        element: <Register />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/forgot_password",
        element: <ForgotPassword />,
      },
      {
        path: "/reset_password",
        element: <ResetPassword />,
      },
      {
        path: "/set_role",
        element: <SetRole />,
      },
    ],
  },
  {
    path: "/confirm_account",
    element: <Confirmation />,
  },
  {
    path: "/brand",
    element: <RoleGuard role="brand" />,
    children: [
      {
        path: "/brand/find-influencers",
        element: (
          <BrandDashboard>
            <FindInfluencers />
          </BrandDashboard>
        ),
      },
      {
        path: "/brand/manage-influencers",
        element: (
          <BrandDashboard>
            <ManageInfluencers />
          </BrandDashboard>
        ),
      },
      {
        path: "/brand/boards",
        element: (
          <BrandDashboard>
            <Boards />
          </BrandDashboard>
        ),
      },
      {
        path: "/brand/account",
        element: <BrandAccount />,
      },
      {
        path: "/brand/payment-profile",
        element: <PaymentProfile />,
      },
      {
        path: "/brand/plans",
        element: <PaymentPlans />,
      },
      {
        path: "/brand/plans/:id/checkout",
        element: <Checkout />,
      },
      {
        index: true,
        element: <Navigate to="find-influencers" />,
      },
      {
        path: "*",
        element: <Navigate to="find-influencers" />,
      },
      {
        path: "/brand/mailbox-connection-callback",
        element: <MailBoxCallback />,
      },
      {
        path: "/brand/outreach-emails",
        element: <OutreachEmails />,
      },
      {
        path: "/brand/email-templates",
        element: <EmailTemplates />,
      },
      {
        path: "/brand/campaigns",
        element: <CampaignsListing />,
      },
      {
        path: "/brand/campaigns/:slug/edit",
        element: <CampaignEdit />,
      },
      {
        path: "/brand/campaigns/:slug",
        element: <ShowCampaign />,
      },
    ],
  },
  {
    path: "/influencer/dashboard",
    element: (
      <ExternalRedirect
        url={`${process.env.REACT_APP_LANDING_PAGE_URL}/creator`}
      />
    ),
  },
  {
    path: "/influencer/account",
    element: (
      <ExternalRedirect
        url={`${process.env.REACT_APP_LANDING_PAGE_URL}/creator`}
      />
    ),
  },
  {
    path: "/influencer/payment-profile",
    element: (
      <ExternalRedirect
        url={`${process.env.REACT_APP_LANDING_PAGE_URL}/creator`}
      />
    ),
  },
]);
