// React imports
import React from "react"
// Custom component imports
import CheckboxButton from "../CheckboxButton"
import RadioButton from "../RadioButton"
// Custom styles
import "./styles.scss"

const Stepper = ({ steps, currentStep }) => {
  return (
    <div className="campaignFormStepper">
      {steps.map((step, index) => {
        const isCurrentStep = index === currentStep
        const isBeforeCurrentStep = index < currentStep
        const isAfterCurrentStep = index > currentStep

        const stepClassName = isCurrentStep || isBeforeCurrentStep ? "stepElement stepElementActive" : "stepElement"

        return (
          <div className={stepClassName} key={index}>
            {isCurrentStep ? <RadioButton checked={true} /> : <CheckboxButton checked={isBeforeCurrentStep} disabled={isAfterCurrentStep} />}
            <span className="stepName">{step}</span>
          </div>
        )
      })}
    </div>
  )
}

export default Stepper
