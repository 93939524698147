// React import
import Card from "react-bootstrap/Card";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

// Assets import
import GmailIcon from "../../../../assets/images/gmail.svg";
import OutlookIcon from "../../../../assets/images/outlook.svg";
import { Button } from "../../../Common/CommonElements";
// Services
import useMailboxService from "../../../../services/useMailboxService";
import { setUser } from "../../../../redux/reducers/authReducer";

//style
import "./style.scss";

// Component to render
const ProviderCard = ({ provider, border }) => {
  //service constants
  const mailboxHookService = useMailboxService();
  const currentUser = useSelector((state) => state.auth?.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const providerGrant = currentUser.connected_mailbox_grants?.find(
    (item) => item.provider === provider
  );

  // handlers
  const handleProviderAccountConnect = () => {
    mailboxHookService
      .connect(provider)
      .then((result) => {
        if (result) {
          window.open(
            result.authorization_url,
            "_blank",
            "noopener,noreferrer"
          );
        }
      })
      .catch((error) => {
        console.log(error.response.data.error);
      });
  };

  const handleProviderAccountDisconnect = () => {
    document
      .getElementById(`${provider}DisconnectSpinner`)
      .classList.remove("d-none");
    mailboxHookService
      .disconnect(providerGrant.slug)
      .then((result) => {
        if (result) {
          dispatch(setUser(result));
          navigate(`/brand/account?activeTab=mailbox`);
        }
      })
      .catch((error) => {
        console.log(error.response.data.error);
      });
  };

  // Html
  return (
    <Card className={border ? "provider-card" : "provider-card border-0"}>
      <Card.Img
        variant="top"
        src={provider == "google" ? GmailIcon : OutlookIcon}
        style={{ width: "4rem", height: "4rem" }}
      />
      <Card.Body className="text-center">
        <Card.Title className="fs-6 mt-3 fw-bold">
          {provider == "google" ? "Gmail" : "Outlook"}
        </Card.Title>

        {!providerGrant && (
          <div className="mt-5">
            <Button
              type="button"
              label="Connect"
              onClick={handleProviderAccountConnect}
              theme={{
                backgroundColor: "#94A3FF",
                color: "#ffffff",
                padding: "0.3rem 1.4rem",
                fontSize: "12px",
              }}
            />
          </div>
        )}
        {providerGrant && (
          <Card.Text className="font-13 fw-light">
            ({providerGrant.email})
          </Card.Text>
        )}

        {providerGrant && (
          <div className="mt-4 position-relative">
            <Spinner
              animation="border"
              variant="dark"
              size="sm"
              className="disconnect-spinner-position me-4 d-none"
              id={`${provider}DisconnectSpinner`}
            />
            <Button
              type="button"
              label="Disconnect"
              onClick={handleProviderAccountDisconnect}
              theme={{
                backgroundColor: "#ffffff",
                color: "#333333",
                padding: "0.3rem 1.4rem",
                fontSize: "12px",
                borderColor: "#94A3FF",
                hover: "#ffffff",
              }}
            ></Button>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default ProviderCard;
