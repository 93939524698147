//React imports
import React, { useState, useCallback } from "react"
import { Dialog } from "@mui/material"
import Cropper from "react-easy-crop"
import RangeSlider from "../RangeSlider"
//Custom styles
import "./styles.scss"
//Custom helpers
import { getCroppedImage } from "./helper"

const ImageCropper = ({ imageSrc, onCropComplete, isOpen, onClose }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState()

  const onCropChange = (newCrop) => {
    setCrop(newCrop)
  }

  const onCropCompleteHandler = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const handleCropConfirm = async () => {
    try {
      let croppedImage = await getCroppedImage(imageSrc, croppedAreaPixels, rotation)
      croppedImage = Object.assign(croppedImage, {
        preview: URL.createObjectURL(croppedImage),
      })
      setZoom(1)
      setRotation(0)
      onCropComplete(croppedImage)
    } catch (error) {
      console.error("Error cropping the image: ", error)
    }
  }

  return (
    <Dialog open={isOpen} onClose={onClose} className="campaignFormImageCropper">
      <div className="modalContainer">
        <div className="heading">Crop your image</div>
        <div className="cropperContainer">
          <Cropper image={imageSrc} crop={crop} zoom={zoom} rotation={rotation} aspect={330 / 280} onCropChange={onCropChange} onCropComplete={onCropCompleteHandler} onZoomChange={setZoom} />
        </div>
        <div className="controls">
          <div className="label">Zoom</div>
          <RangeSlider min={1} max={5} steps={0.1} value={zoom} setValue={setZoom} tooltipDisplay="off" />
          <div className="label">Rotation</div>
          <RangeSlider min={0} max={360} steps={1} value={rotation} setValue={setRotation} tooltipDisplay="off" />
        </div>
        <div className="buttonGroup">
          <button className="actionButton actionButtonDark" onClick={handleCropConfirm}>
            Confirm
          </button>
          <button className="actionButton actionButtonDanger" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </Dialog>
  )
}

export default ImageCropper
