// React import
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

// Custom Component Import
import ProviderCard from "../ProviderCard";

// Assets
import CrossIcon from "../../../../assets/images/cross-icon-white.svg";

const ConnectModal = ({ openConnectModal, setOpenConnectModal }) => {
  // helper constants
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "39rem",
    padding: "30px",
    margin: "auto",
    borderRadius: "15px",
    backgroundColor: "#F6F7FB",
    border: "none !important",
  };

  return (
    <>
      <Modal
        className="stripe-payment-modal"
        open={openConnectModal}
        onClose={() => {
          setOpenConnectModal(false);
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openConnectModal}>
          <Box sx={style}>
            <div className="d-flex flex-row justify-content-between align-items-baseline">
              <div className="d-flex flex-column">
                <span className="fs-6 fw-normal">
                  <b>Mailbox Setup</b>
                </span>

                <span className="mt-3 font-13 fw-light">
                  Connect your account to send emails to Hive contacts.
                </span>
              </div>
              <img
                src={CrossIcon}
                className="cursor-pointer"
                alt=""
                onClick={() => setOpenConnectModal(false)}
              />
            </div>
            <div className="d-flex justify-content-between mt-4 mb-4">
              <ProviderCard provider={"google"} />
              <ProviderCard provider={"microsoft"} />
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ConnectModal;
