import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import usePaymentPlanService from "../../../services/usePaymentPlanService"
import PlanCard from "../../../components/PaymentPlans/PlanCard"
import { FullScreenLoader } from "../../../components/Common/Loaders"
import "./styles.scss"
import saveIcon from "../../../assets/images/save_icon.png"
import arrow from "../../../assets/images/arrow.svg"
import arrowIcon from "../../../assets/images/arrow_pricing_plan.svg"
import BackButton from "../../../assets/images/back_arrow.svg"

const PaymentPlans = () => {
  const navigate = useNavigate()
  const paymentPlanService = usePaymentPlanService()

  const [paymentPlans, setPaymentPlans] = useState()
  const [switchState, setSwitchState] = useState("annually")
  const [planAndAmountHash, setPlanAndAmountHash] = useState({})

  const switchPlans = () => {
    setSwitchState((prev) => {
      return prev === "monthly" ? "annually" : "monthly"
    })
  }

  useEffect(() => {
    paymentPlanService.list().then((data) => {
      setPaymentPlans(data.payment_plans)
      setPlanAndAmountHash(data.meta["subscribed_plan_amount_left"])
    })
  }, [])

  const proceedToCheckout = (id) => {
    navigate(`/brand/plans/${id}/checkout?planDuration=${switchState}`)
  }

  return (
    <div className="pricing_plan_main">
      <div className="back-button" onClick={() => navigate("/brand")}>
        <img src={BackButton} alt="" />
      </div>
      <div className="text-center bold-heading-text mt-4">Select the plan that suits you best.</div>
      <div className="switch_section mt-2">
        Month
        <div className={switchState === "monthly" ? "switch month_on" : "switch annual_on"} onClick={switchPlans}>
          <div className="switch_inner">
            <img src={arrowIcon} alt="" />
          </div>
        </div>
        <div className="sales_arrow_icons">
          Annually
          <img className="arrow_icon" alt="" src={arrow} />
          <img className="sales_icon" alt="" src={saveIcon} />
        </div>
      </div>
      <div className="pricing_plan_cards">
        {paymentPlans?.map((plan) => (
          <PlanCard key={plan.id} plan={plan} switchState={switchState} proceedToCheckout={proceedToCheckout} planAndAmountHash={planAndAmountHash} />
        ))}
      </div>
      {!paymentPlans && <FullScreenLoader state={true} />}
    </div>
  )
}

export default PaymentPlans
