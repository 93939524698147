// React
import React, { useRef, useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { format } from "date-fns";

// Custom Component
import GrowLoader from "../../../components/Common/GrowLoader";
import Header from "../../../components/Common/Header";
import EmailViewModal from "../../../components/Mailboxes/EmailViewModal";
import { Button } from "../../../components/Common/CommonElements";

// assets
import AllActivityEmailIcon from "../../../assets/images/all_email_activity.svg";
import SentEmailActivityIcon from "../../../assets/images/sent_emails.svg";
import OpenedEmailActivityIcon from "../../../assets/images/opened_emails.svg";
import ClickedEmailActivityIcon from "../../../assets/images/clicked_emails.svg";
import RepliedEmailActivityIcon from "../../../assets/images/replied_emails.svg";
import BouncedEmailActivityIcon from "../../../assets/images/bounced_emails.svg";
import BlackCalendarIcon from "../../../assets/images/icons/black_calendar.svg";

// Services
import useMailboxesActivitiesService from "../../../services/useMailboxesActivitiesService";

// style
import "./style.scss";

// component to render
const OutreachEmails = () => {
  // Use States
  const [emailMessagesActivities, setEmailMessagesActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedDuration, setSelectedDuration] = useState("today");
  const [openViewEmailModal, setOpenViewEmailModal] = useState(false);
  const [emailDetails, setEmailDetails] = useState({});
  const [customDateRangeLabel, setCustomDateRangeLabel] =
    useState("Custom Date Range");
  const [dateRangeShow, setDateRangeShow] = useState(false);
  const [dateRangeValue, setDateRangeValue] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [statuses, setStatuses] = useState({
    all_activities: {
      title: "All Activities",
      count: 0,
      icon: AllActivityEmailIcon,
    },
    sent: {
      title: "Sent",
      count: 0,
      icon: SentEmailActivityIcon,
    },
    opened: {
      title: "Opened",
      count: 0,
      icon: OpenedEmailActivityIcon,
    },
    clicked: {
      title: "Clicked",
      count: 0,
      icon: ClickedEmailActivityIcon,
    },
    replied: {
      title: "Replied",
      count: 0,
      icon: RepliedEmailActivityIcon,
    },
    bounced: {
      title: "Bounced",
      count: 0,
      icon: BouncedEmailActivityIcon,
    },
  });
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedActivity, setSelectedActivity] = useState("all_activities");

  // use Refs
  const containerRef = useRef(null);
  const pickerRef = useRef(null);

  const scrollToBottom = () => {
    setTimeout(() => {
      if (containerRef.current) {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      }
    }, 500);
  };

  // Service Constants
  const mailboxesActivities = useMailboxesActivitiesService();

  // Use Effects
  useEffect(() => {
    mailboxesActivities.stats(selectedDuration).then((data) => {
      if (data) {
        let allActivitiesCount = 0;
        Object.keys(data).map((key, index) => {
          allActivitiesCount += data[key];
        });
        updateStatusCount(data, allActivitiesCount);
      }
    });
  }, [selectedDuration]);

  useEffect(() => {
    setLoading(true);
    mailboxesActivities
      .list(pageNo, selectedDuration, selectedActivity)
      .then((data) => {
        if (data) {
          const newItems = data.tracked_activities;
          if (pageNo == 1) {
            setEmailMessagesActivities(newItems);
          } else {
            setEmailMessagesActivities((prevItems) => [
              ...prevItems,
              ...newItems,
            ]);
            scrollToBottom();
          }

          setTotalPages(data.meta.pagination.pages);
          setLoading(false);
        }
      });
  }, [pageNo, selectedActivity, selectedDuration]);

  const handleClickOutside = (event) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      setDateRangeShow(false);
    }
  };

  useEffect(() => {
    if (dateRangeShow) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dateRangeShow]);

  // handlers
  const updateStatusCount = (fetchedCountsHash, totalCount) => {
    setStatuses((prevStatuses) => {
      const updatedStatuses = {};
      for (const key in prevStatuses) {
        updatedStatuses[key] = {
          ...prevStatuses[key],
          count:
            key === "all_activities" ? totalCount : fetchedCountsHash[key] || 0,
        };
      }
      return updatedStatuses;
    });
  };

  const handleActivityCardClick = (activity) => {
    setPageNo(1);
    setSelectedActivity(activity);
  };

  const onDateRangeChange = (duration, customDateRangelLabel = null) => {
    setPageNo(1);
    setDateRangeShow(false);
    setCustomDateRangeLabel(
      customDateRangelLabel ? customDateRangelLabel : "Custom Date Range"
    );
    setSelectedDuration(duration);
  };

  const handleOpenEmailViewModal = (details) => {
    setEmailDetails(details);
    setOpenViewEmailModal(true);
  };

  const handleDateRangeValue = (selectedDateRange) => {
    const startDate = selectedDateRange[0].startDate;
    const endDate = selectedDateRange[0].endDate;
    setDateRangeValue(selectedDateRange);
    if (endDate > startDate) {
      const combinedDate = `${format(startDate, "dd/MM/yy")} - ${format(
        endDate,
        "dd/MM/yy"
      )}`;
      onDateRangeChange(combinedDate, combinedDate);
    }
  };

  // Component to render

  return (
    <>
      <Header />
      <div className="p-4">
        <div className="d-flex justify-content-between align-items-center">
          <h4>Activities</h4>

          <div className="switch-tab-border platform-switch-container">
            <button
              className={`platform-switch ${
                selectedDuration === "today" ? "active" : ""
              }`}
              onClick={() => onDateRangeChange("today")}
            >
              <div className="name">Today</div>
            </button>
            <button
              className={`platform-switch ${
                selectedDuration === "week" ? "active" : ""
              }`}
              onClick={() => onDateRangeChange("week")}
            >
              <div className="name">This Week</div>
            </button>
            <button
              className={`platform-switch ${
                selectedDuration === "month" ? "active" : ""
              }`}
              onClick={() => onDateRangeChange("month")}
            >
              <div className="name">This Month</div>
            </button>
            <button
              className={`platform-switch ${
                ["today", "month", "week"].includes(selectedDuration)
                  ? ""
                  : "active"
              }`}
              onClick={() => {
                setDateRangeShow(!dateRangeShow);
              }}
            >
              <div className="name">
                <img
                  className="me-1 mb-1"
                  style={{ width: "20px" }}
                  src={BlackCalendarIcon}
                />
                {customDateRangeLabel}
              </div>
            </button>
          </div>
        </div>
        {dateRangeShow && (
          <div className="d-flex justify-content-end position-relative">
            <span ref={pickerRef} className="position-absolute">
              <DateRangePicker
                onChange={(item) => handleDateRangeValue([item.selection])}
                months={2}
                ranges={dateRangeValue}
                direction="horizontal"
              />
            </span>
          </div>
        )}

        <div className="mt-3 d-flex flex-wrap gap-3">
          {Object.keys(statuses).map((activity, key) => (
            <Card
              key={key}
              className={
                activity === selectedActivity
                  ? "activity-card-active email-activity-card"
                  : "email-activity-card"
              }
              onClick={() => handleActivityCardClick(activity)}
            >
              <Card.Img
                variant="top"
                src={statuses[activity].icon}
                style={{ width: "3rem", height: "3rem", marginTop: "15px" }}
              />
              <Card.Body className="text-center">
                <Card.Title className="activity-title-heading mt-2">
                  {statuses[activity].title}
                </Card.Title>
                <Card.Text className="fs-6 mt-2 fw-light">
                  {statuses[activity].count}
                </Card.Text>
              </Card.Body>
            </Card>
          ))}
        </div>
        <div className="mt-3 header-font-16">All Activity</div>
        <div className="activity-list-section mt-2">
          <div className="row activity-list-section-header ms-1 me-1">
            <span className="col-4 header-font-14">Activity</span>
            <span className="col-4 header-font-14">Receipient</span>
            <span className="col-4 header-font-14">Subject</span>
          </div>
          <div className="activity-list-body" ref={containerRef}>
            {!loading &&
              (emailMessagesActivities.length <= 0 ? (
                <div className="d-flex justify-content-center align-items-center h-100">
                  No activity right now
                </div>
              ) : (
                <div>
                  {emailMessagesActivities.map((activity, index) => (
                    <div
                      key={index}
                      className="row mt-3 ms-1 me-1 list-row-border-section"
                      onClick={() => {
                        handleOpenEmailViewModal({
                          sentToImageUrl:
                            activity.email_message.sent_to_profile_picture_url,
                          sentToName: activity.email_message.sent_to_name,
                          statusIcon: statuses[activity.action].icon,
                          status: statuses[activity.action].title,
                          created_at: activity.created_at,
                          subject: activity.email_message.subject,
                          content: activity.email_message.content,
                        });
                      }}
                    >
                      <div className="col-4 d-flex">
                        <img
                          src={statuses[activity.action].icon}
                          style={{ width: "2.5rem", height: "2.5rem" }}
                        />
                        <div className="col-4 ms-2 d-flex flex-column w-100">
                          <div className="list-row-header-font-13">
                            {statuses[activity.action].title}
                          </div>
                          <div className="detail-gray-text">
                            {activity.created_at}
                          </div>
                        </div>
                      </div>

                      <div className="d-flex flex-column col-4">
                        <div className="list-row-header-font-13">
                          {activity.email_message.sent_to_name}
                        </div>
                        <div className="detail-gray-text">
                          {activity.email_message.sent_to_email}
                        </div>
                      </div>

                      <div className="col-4 d-flex flex-column">
                        <div className="list-row-header-font-13">
                          {activity.email_message.subject}
                        </div>
                        <div className="detail-gray-text">
                          {activity.email_message.content
                            .replace(/<\/?[^>]+(>|$)/g, "")
                            .replace(/&nbsp;/g, " ")
                            .trim()
                            .slice(0, 80)}
                        </div>
                      </div>
                    </div>
                  ))}
                  {pageNo < totalPages && (
                    <div className="mt-3 d-flex justify-content-center">
                      <Button
                        label="Load More"
                        onClick={() => {
                          setPageNo(pageNo + 1);
                        }}
                        theme={{
                          backgroundColor: "#94A3FF",
                          color: "#ffffff",
                          padding: "0.4rem 1.8rem",
                          fontSize: "14px",
                        }}
                      />
                    </div>
                  )}
                </div>
              ))}
            {loading && <GrowLoader />}
          </div>
          {openViewEmailModal && (
            <EmailViewModal
              openViewEmailModal={openViewEmailModal}
              setOpenViewEmailModal={setOpenViewEmailModal}
              emailDetails={emailDetails}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default OutreachEmails;
