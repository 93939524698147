// React import
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

// Assets
import CrossIcon from "../../../assets/images/cross-icon-white.svg";

const EmailViewModal = ({
  openViewEmailModal,
  setOpenViewEmailModal,
  emailDetails,
}) => {
  // helper constants
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "45rem",
    padding: "30px",
    margin: "auto",
    borderRadius: "15px",
    backgroundColor: "#FFFFFF",
    border: "none !important",
  };

  return (
    <>
      <Modal
        className="stripe-payment-modal"
        open={openViewEmailModal}
        onClose={() => {
          setOpenViewEmailModal(false);
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openViewEmailModal}>
          <Box sx={style}>
            <div className="d-flex justify-content-between">
              <div>
                <span className="fs-6">
                  <b>Outreach Email Detail</b>
                </span>
              </div>
              <img
                src={CrossIcon}
                className="cursor-pointer"
                alt=""
                onClick={() => setOpenViewEmailModal(false)}
              />
            </div>

            <div className="d-flex align-items-center mt-3">
              <img
                src={emailDetails.sentToImageUrl}
                style={{
                  width: "3.5rem",
                  height: "3.5rem",
                  border: "0.5px solid #E2E8F0",
                  borderRadius: "50px",
                }}
              />
              <div className="ms-2 w-100">{emailDetails.sentToName}</div>
            </div>

            <div className="d-flex mt-3 ms-1 align-items-center">
              <img
                src={emailDetails.statusIcon}
                style={{ width: "2.8rem", height: "2.8rem" }}
              />
              <div className="col-4 ms-3 d-flex flex-column w-100">
                <div className="list-row-header-font-13">
                  {emailDetails.status}
                </div>
                <div className="detail-gray-text">
                  {emailDetails.created_at}
                </div>
              </div>
            </div>
            <div
              style={{
                border: "1px solid #E2E8F0",
                backgroundColor: "#F7F8FA",
                borderRadius: "20px",
                padding: "13px",
                fontSize: "13px",
                fontWeight: "200",
              }}
              className="mt-3"
            >
              <span>
                <b>Subject:</b>
              </span>
              {emailDetails.subject}
            </div>

            <div
              style={{
                border: "1px solid #E2E8F0",
                backgroundColor: "#F7F8FA",
                borderRadius: "20px",
                padding: "13px",
                fontSize: "13px",
                fontWeight: "200",
                minHeight: "100px",
                maxHeight: "250px",
                overflow: "auto",
              }}
              className="mt-3"
            >
              <div dangerouslySetInnerHTML={{ __html: emailDetails.content }} />
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default EmailViewModal;
