import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useSubscribedPlanService from "../../../services/useSubscribedPlanService";
import notify from "../../Common/Toast";
import ConfirmationModal from "../../Common/ConfirmationModal";
import CancelAlertIcon from "../../../assets/images/cancel_alert.svg";
import TooltipIcon from "../../../assets/images/tooltip_icon.svg";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch } from "react-redux";
import { setUser, setCredits } from "../../../redux/reducers/authReducer";
import "./style.scss";

const Billing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const subscribedPlanService = useSubscribedPlanService();
  const [subscribedPlan, setSubscribedPlan] = useState({});
  const [cancelModalClicked, setCancelModalClicked] = useState(false);
  const [reactivateModalClicked, setReactivateModalClicked] = useState(false);
  const [triggerPaymentModalClicked, setTriggerPaymentModalClicked] =
    useState(false);
  const [pageRefresh, setPageRefresh] = useState(false);

  useEffect(() => {
    subscribedPlanService
      .detail()
      .then((data) => {
        setSubscribedPlan(data);
      })
      .catch((error) => {
        notify.error(error);
      });
  }, [pageRefresh]);

  const cancelPlan = () => {
    subscribedPlanService
      .cancel(subscribedPlan.id)
      .then((data) => {
        setCancelModalClicked(false);
        if (data) {
          setSubscribedPlan(data);
          notify.success("Plan cancelled successfully.");
        }
      })
      .catch((error) => {
        notify.error(error);
      });
  };

  const handleTriggerPayment = () => {
    document.getElementById("triggerSpinner").classList.remove("d-none");
    closeTriggerPaymentModal();
    subscribedPlanService
      .triggerPayment(subscribedPlan.id)
      .then((data) => {
        if (data) {
          setUserData(data);
          notify.success("Payment completed successfully.");
        }
      })
      .catch((error) => {
        notify.error(error);
      });
  };

  const handleReactivatePlan = () => {
    document.getElementById("triggerSpinner").classList.remove("d-none");
    closeReactivateModal();
    subscribedPlanService
      .reactivateCancelledPlan(subscribedPlan.id)
      .then((data) => {
        if (data) {
          setUserData(data);
          notify.success("Subscribed plan Reactivated successfully.");
        }
      })
      .catch((error) => {
        notify.error(error);
      });
  };

  const closeCancelModal = () => {
    setCancelModalClicked(false);
  };
  const closeReactivateModal = () => {
    setReactivateModalClicked(false);
  };
  const closeTriggerPaymentModal = () => {
    setTriggerPaymentModalClicked(false);
  };

  const setUserData = (data) => {
    dispatch(setUser(data));
    dispatch(setCredits(data.credits));
    setPageRefresh(!pageRefresh);
  };

  return subscribedPlan ? (
    <div>
      <div className="tab-pane w-650 position-relative">
        {subscribedPlan.status == "payment_pending" && (
          <img src={CancelAlertIcon} className="pending-payment-icon" alt="" />
        )}
        <div className="mb-3">
          <h5>Current Plan</h5>
        </div>
        <div className="renew-date-text mb-3">
          <b>Renewal Date: </b> {subscribedPlan.expire_at}
        </div>
        <div className=" highlight-section active-plan-detail">
          <div>{subscribedPlan.plan_name}</div>
          <div>
            <span className="price-text">${subscribedPlan.amount}</span> per
            month
          </div>
        </div>

        <div className="d-flex align-items-center mt-3">
          <span>Status: </span>

          <span
            className={`${
              subscribedPlan.status == "active" ? "text-success" : "text-danger"
            } ms-1`}
          >
            {subscribedPlan?.status
              ?.split("_")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")}
          </span>
        </div>

        {subscribedPlan.status == "active" && (
          <div className="mt-4">
            <button
              className="cancel-btn"
              onClick={() => {
                setCancelModalClicked(true);
              }}
            >
              Cancel Plan
            </button>
          </div>
        )}

        {subscribedPlan.status == "payment_pending" && (
          <button
            className="trigger-payment-btn mt-4"
            onClick={() => {
              setTriggerPaymentModalClicked(true);
            }}
          >
            <div
              class="spinner-border spinner-scale me-2 d-none text-dark"
              role="status"
              id="triggerSpinner"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
            Trigger Payment
            <Tooltip
              title="This action will try to attempt payment using default payment method."
              placement="top"
              arrow
            >
              <img
                className="ms-2 cursor-pointer"
                src={TooltipIcon}
                style={{ width: "13px" }}
              />
            </Tooltip>
          </button>
        )}
        {subscribedPlan.status == "cancelled" && (
          <button
            className="trigger-payment-btn mt-4"
            onClick={() => {
              setReactivateModalClicked(true);
            }}
          >
            <div
              class="spinner-border spinner-scale me-2 d-none text-dark"
              role="status"
              id="triggerSpinner"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
            Re Activate Plan
            <Tooltip
              title="This action will reactivate your current plan."
              placement="top"
              arrow
            >
              <img
                className="ms-2 cursor-pointer"
                src={TooltipIcon}
                style={{ width: "13px" }}
              />
            </Tooltip>
          </button>
        )}
      </div>
      <div className="tab-pane w-650 mt-4">
        <h5 className="mb-3">Billing History</h5>
        <hr></hr>
        <table className="w-100">
          {subscribedPlan?.billing_histories?.map((history) => (
            <tr className="mb-5">
              <td className="renew-date-text pb-4 pe-3">
                {history.created_at}
              </td>
              <td className="renew-date-text pb-4 pe-2">${history.amount}</td>
              <td className="renew-date-text pb-4 pe-2">
                {history.credits}
                <span className="credits-text"> (Credits)</span>
              </td>
              <td className="pb-4 pe-3">
                <span className="renew-date-text highlight-section border-0 ps-3 pe-3 pt-1 pb-1">
                  Paid
                </span>
              </td>
              <td className="renew-date-text pb-4 pe-3">{history.plan_name}</td>
              <td className="renew-date-text pb-4 pe-3">
                <a
                  className="renew-date-text pb-4 pe-3"
                  href={history.invoice_download_url}
                >
                  Download Invoice
                </a>
              </td>
            </tr>
          ))}
        </table>
      </div>
      {cancelModalClicked && (
        <ConfirmationModal
          open={true}
          onClose={closeCancelModal}
          onConfirm={cancelPlan}
          cancelButtonText={"No"}
          confirmButtonText={"Cancel Plan"}
          confirmButtonColor={"#F65353"}
          modalHeadingText={"Cancel"}
          confirmationText={`Are you sure you want to make to cancel "${subscribedPlan.plan_name}" plan`}
          cancelAlert={true}
        />
      )}
      {triggerPaymentModalClicked && (
        <ConfirmationModal
          open={true}
          onClose={closeTriggerPaymentModal}
          onConfirm={handleTriggerPayment}
          cancelButtonText={"Cancel"}
          confirmButtonText={"Trigger Payment"}
          confirmButtonColor={"#5fce57"}
          modalHeadingText={"Payment"}
          confirmationText={`Are you sure you want to trigger payment for "${subscribedPlan.plan_name}" plan`}
        />
      )}
      {reactivateModalClicked && (
        <ConfirmationModal
          open={true}
          onClose={closeReactivateModal}
          onConfirm={handleReactivatePlan}
          cancelButtonText={"Cancel"}
          confirmButtonText={"Reactivate"}
          confirmButtonColor={"#5fce57"}
          modalHeadingText={"Reactivate"}
          confirmationText={`Are you sure you want to make to reactivate "${subscribedPlan.plan_name}" plan`}
        />
      )}
    </div>
  ) : (
    <div>
      <div className="tab-pane w-650">
        <div className="text-center text-danger">
          No active subscription found. Subscribe now to access all features.
        </div>
        <div className="text-center">
          <button
            className="view-plans-button mt-4"
            onClick={() => {
              navigate("/brand/plans");
            }}
          >
            View Plans
          </button>
        </div>
      </div>
    </div>
  );
};

export default Billing;
