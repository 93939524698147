import useBaseService from "./useBaseService";
import notify from "../components/Common/Toast";

const useMailboxService = () => {
  const axiosRequest = useBaseService().axiosRequest();

  const connect = async (provider) => {
    try {
      const response = await axiosRequest.get(
        `/mailboxes/connect?provider=${provider}`
      );
      return response.data;
    } catch (error) {
      notify.error(error.response.data.error);
      return false;
    }
  };

  const disconnect = async (slug) => {
    try {
      const response = await axiosRequest.delete(
        `/mailboxes/${slug}/disconnect`
      );
      if (response.data) {
        notify.success("Mailbox disconnected successfully.");
      }
      return response.data;
    } catch (error) {
      notify.error(error.response.data.error);
      return false;
    }
  };

  const verifyAuthorizationCode = async (code) => {
    try {
      const response = await axiosRequest.post(
        "/mailboxes/verify_authorization_code",
        { code: code }
      );
      notify.success("Mailbox connected successfully.");
      return response.data;
    } catch (error) {
      notify.error(error.response.data.error);
      return false;
    }
  };

  const sendEmail = async (emailData) => {
    try {
      const response = await axiosRequest.post(
        `/mailboxes/${emailData.from.value}/send_email`,
        { email_options: emailData }
      );
      notify.success(response.data.message);
      return response.data;
    } catch (error) {
      notify.error(error.response.data.error);
      return false;
    }
  };

  return {
    connect,
    disconnect,
    verifyAuthorizationCode,
    sendEmail,
  };
};

export default useMailboxService;
