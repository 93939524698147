//React imports
import React from "react"
import { Dialog } from "@mui/material"
import CampaignPreview from "../../../../CampaignPreview"
//Custom styles
import "./styles.scss"

const Preview = ({ campaign, isOpen, onClose }) => {
  return (
    <Dialog open={isOpen} onClose={onClose} className="campaignFormPreview">
      {campaign && (
        <div className="modalContainer">
          <div className="header">
            <button className="closeButton" onClick={onClose}>
              Close Preview
            </button>
          </div>
          <CampaignPreview campaign={campaign} />
        </div>
      )}
    </Dialog>
  )
}

export default Preview
