import * as Yup from "yup"

export const LocationsValidationSchema = Yup.object().shape({
  category: Yup.object().required("Please select a category."),
  locations: Yup.array()
    .nullable()
    .test("at-least-one-not-destroyed", "Please select at least one location, or choose the worldwide option.", (locations) => {
      return locations.some((location) => location._destroy !== true)
    }),
})
