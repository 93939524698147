// React import
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

//import EmailCompose from "../../EmailCompose";
import EmailCompose from "../EmailCompose";
// Assets
import CrossIcon from "../../../assets/images/cross-icon-white.svg";

const EmailModal = ({
  openConnectModal,
  setOpenConnectModal,
  sentToDetails,
}) => {
  // helper constants
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "45rem",
    padding: "30px",
    margin: "auto",
    borderRadius: "15px",
    backgroundColor: "#FFFFFF",
    border: "none !important",
  };

  return (
    <>
      <Modal
        className="stripe-payment-modal"
        open={openConnectModal}
        onClose={() => {
          setOpenConnectModal(false);
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openConnectModal}>
          <Box sx={style}>
            <div className="d-flex justify-content-between">
              <div>
                <span className="fs-6">
                  <b>New Message</b>
                </span>
              </div>
              <img
                src={CrossIcon}
                className="cursor-pointer"
                alt=""
                onClick={() => setOpenConnectModal(false)}
              />
            </div>

            <EmailCompose
              sentToDetails={sentToDetails}
              setOpenConnectModal={setOpenConnectModal}
            />
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default EmailModal;
