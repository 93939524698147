// React imports
import React, { useState, useEffect } from "react"
import { Editor } from "react-draft-wysiwyg"
import draftToHtml from "draftjs-to-html"
import { EditorState, ContentState, convertToRaw, convertFromHTML } from "draft-js"
// Custom styles
import "./styles.scss"

const RichTextInput = ({ label, description, value, handleChange }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  let contentState = ""

  useEffect(() => {
    if (value) {
      const blocksFromHTML = convertFromHTML(value)
      contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)
      setEditorState(EditorState.createWithContent(contentState))
    }
  }, [])

  const handleEditorStateChange = (state) => {
    setEditorState(state)
    const contentState = state.getCurrentContent()
    const rawContent = convertToRaw(contentState)
    handleChange(draftToHtml(rawContent))
  }

  const toolbarOptions = {
    options: ["inline", "fontSize", "blockType", "list", "textAlign", "link"],
    inline: {
      options: ["bold", "italic", "underline"],
    },
    list: {
      options: ["unordered", "ordered"],
    },
    blockType: {
      inDropdown: false,
      options: ["Normal", "H1", "H2"],
    },
  }

  return (
    <div className="campaignFormRichTextInput">
      <div className="label">{label}</div>
      <div className="description">{description}</div>
      <Editor
        editorState={editorState}
        toolbarClassName="toolbarClass"
        wrapperClassName="wrapperClass"
        editorClassName="editorClass"
        onEditorStateChange={handleEditorStateChange}
        toolbar={toolbarOptions}
        toolbarPosition="bottom"
        placeholder="Enter your description here..."
      />
    </div>
  )
}

export default RichTextInput
