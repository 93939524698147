import * as Yup from "yup"

export const budgetOptions = [
  { label: "Intro Project ($50 – 100 USD)", value: "Intro Project ($50 – 100 USD)" },
  { label: "Starter Project ($100 – 250 USD)", value: "Starter Project ($100 – 250 USD)" },
  { label: "Essentials Project ($250 – 500 USD)", value: "Essentials Project ($250 – 500 USD)" },
  { label: "Growing Project ($500 – 1,000 USD)", value: "Growing Project ($500 – 1,000 USD)" },
  { label: "Established Project ($1,000 – 2,000 USD)", value: "Established Project ($1,000 – 2,000 USD)" },
  { label: "Extended Project ($2,000 – 3,500 USD)", value: "Extended Project ($2,000 – 3,500 USD)" },
  { label: "Expansive Project ($3,500 – 5,000 USD)", value: "Expansive Project ($3,500 – 5,000 USD)" },
  { label: "Advanced Project ($5,000 – 7,500 USD)", value: "Advanced Project ($5,000 – 7,500 USD)" },
  { label: "Premium Project ($7,500 – 10,000 USD)", value: "Premium Project ($7,500 – 10,000 USD)" },
  { label: "Elite Project ($15,000+ USD)", value: "Elite Project ($15,000+ USD)" },
]

export const OffersValidationSchema = Yup.object().shape({
  creatorType: Yup.string().required("Creator type must be selected"),
  incentives: Yup.object()
    .shape({
      fee: Yup.object().shape({
        selected: Yup.boolean(),
        value: Yup.string().when("selected", {
          is: true,
          then: () => Yup.string().required("This field is required"),
          otherwise: () => Yup.string().nullable(),
        }),
      }),
      product: Yup.object().shape({
        selected: Yup.boolean(),
        value: Yup.number().when("selected", {
          is: true,
          then: () => Yup.number().positive("Value must be greater than zero").integer("Value cannot be a decimal").required("This field is required"),
          otherwise: () => Yup.number().nullable(),
        }),
        description: Yup.string().when("selected", {
          is: true,
          then: () => Yup.string().required("Product description is required"),
          otherwise: () => Yup.string().nullable(),
        }),
      }),
      commission: Yup.object().shape({
        selected: Yup.boolean(),
        value: Yup.number().when("selected", {
          is: true,
          then: () => Yup.number().positive("Value must be greater than zero").max(100, "Value must be less than 100").integer("Value cannot be a decimal").required("This field is required"),
          otherwise: () => Yup.number().nullable(),
        }),
      }),
    })
    .test("at-least-one-incentive-selected", "At least one incentive must be selected", function (value) {
      const { fee, product, commission } = value
      return fee.selected || product.selected || commission.selected
    }),
})
