import useBaseService from "./useBaseService";
import notify from "../components/Common/Toast";

const useMailboxesActivitiesService = () => {
  const axiosRequest = useBaseService().axiosRequest();

  const stats = async (duration) => {
    try {
      const response = await axiosRequest.get(
        `/mailboxes_activities/stats?duration=${duration}`
      );
      return response.data;
    } catch (error) {
      notify.error(error.response.data.error);
      return false;
    }
  };

  const list = async (pageNo, duration, action) => {
    try {
      const response = await axiosRequest.get(
        `/mailboxes_activities?page=${pageNo}&duration=${duration}&activity_status=${
          action == "all_activities" ? "" : action
        }`
      );
      return response.data;
    } catch (error) {
      notify.error(error.response.data.error);
      return false;
    }
  };

  return {
    stats,
    list,
  };
};

export default useMailboxesActivitiesService;
