// React imports
import React from "react"
// Custom component imports
import TextInput from "../Common/TextInput"
import RichTextInput from "../Common/RichTextInput"
import TagsEnterInput from "../Common/TagsEnterInput"
// Custom styles
import "./styles.scss"

const Details = ({ values: { title, description, hashtags, mentions }, setFieldValue, errors, displayErrors }) => {
  const handleChange = (fieldName) => (value) => {
    setFieldValue(fieldName, value)
  }

  return (
    <div className="campaignFormDetailsStep">
      <div className="formFieldContainer">
        <TextInput label="Campaign Title" limit={50} value={title} setValue={handleChange("title")} />
        {displayErrors && errors?.title && <div className="errorMessage">{errors.title}</div>}
      </div>
      <div className="formFieldContainer">
        <RichTextInput
          label="Describe Your Offer"
          description="Explain the type of content you're looking for (e.g., Instagram Reels, TikTok videos) and any specific guidlines or themes. Mention if youd'd like influencers to share personal stories, demonstrate product usage, or focus on a particular setting."
          value={description}
          handleChange={handleChange("description")}
        />
        {displayErrors && errors?.description && <div className="errorMessage">{errors.description}</div>}
      </div>
      <div className="formFieldContainer">
        <TagsEnterInput value={hashtags} handleChange={handleChange("hashtags")} label="Hashtags" subtext="(Optional)" />
        {displayErrors && errors?.hashtags && <div className="errorMessage">{errors.hashtags}</div>}
      </div>
      <div className="formFieldContainer">
        <TagsEnterInput value={mentions} handleChange={handleChange("mentions")} label="Mentions" subtext="(Optional)" />
        {displayErrors && errors?.mentions && <div className="errorMessage">{errors.mentions}</div>}
      </div>
    </div>
  )
}

export default Details
