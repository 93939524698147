// React imports
import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Formik, Form } from "formik";
import { Dialog } from "@mui/material";
// Custom component imports
import Stepper from "./components/Common/Stepper";
import FloatingControls from "./components/Common/FloatingControls";
import Preview from "./components/Common/Preview";
// Services
import useCampaignService from "../../../services/useCampaignService";
// Helpers
import {
  steps,
  stepNames,
  parseCampaignObject,
  parsePreviewObject,
  defaultValues,
} from "./helper";
// Custom styles
import "./styles.scss";
//Assets
import { CrossIcon } from "../../../assets/images/Common";

const Wizard = ({
  initialValues = defaultValues,
  editMode = false,
  stepNumber = 0,
  wizardIsOpen,
  closeWizard,
  setCampaign,
  campaignListLoading,
  setCampaignListLoading,
}) => {
  const [step, setStep] = useState(stepNumber);
  const [displayErrors, setDisplayErrors] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [previewIsOpen, setPreviewOpen] = useState(false);
  const [previewObject, setPreviewObject] = useState();

  const formikRef = useRef(null);

  const currentUser = useSelector((state) => state.auth?.user);

  const campaignService = useCampaignService();

  const navigate = useNavigate();

  const isFirstStep = step === 0;
  const isLastStep = step === steps.length - 1;

  const handleSubmit = async (values) => {
    setLoading(true);
    const parsedObject = parseCampaignObject(values);

    const method = editMode ? campaignService.edit : campaignService.create;

    method(parsedObject, values.id)
      .then((data) => {
        if (data) {
          closeWizard();
          if (editMode) {
            navigate(`/brand/campaigns/${data.slug}/edit`);
            setCampaign(data);
          } else {
            navigate("/brand/campaigns");
            setCampaignListLoading(!campaignListLoading);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleNext = (validateForm) => {
    validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        setStep(step + 1);
        window.scrollTo({
          top: 0,
        });
        setDisplayErrors(false);
      } else {
        setDisplayErrors(true);
      }
    });
  };

  const handlePrev = () => {
    setStep(step - 1);
    window.scrollTo({
      top: 0,
    });
    setDisplayErrors(false);
  };

  const openPreview = () => {
    const parsedObject = parsePreviewObject(
      formikRef.current.values,
      currentUser
    );
    setPreviewObject(parsedObject);
    setPreviewOpen(true);
  };

  const closePreview = () => {
    setPreviewOpen(false);
  };

  return (
    <Dialog
      open={wizardIsOpen}
      onClose={closeWizard}
      className="campaignWizard"
    >
      <div className="modalContainer">
        <div className="header">
          <img
            src={CrossIcon}
            alt=""
            className="closeIcon"
            onClick={closeWizard}
          />
        </div>
        <div className="campaignFormContainer">
          <Formik
            innerRef={formikRef}
            enableReinitialize
            initialValues={initialValues}
            validationSchema={steps[step].validationSchema}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({ values, setFieldValue, errors, validateForm }) => {
              return (
                <Form>
                  <div className="mainHeading">{steps[step].heading}</div>
                  {!editMode && (
                    <Stepper
                      steps={stepNames}
                      currentStep={step}
                      setCurrentStep={setStep}
                    />
                  )}
                  {React.createElement(steps[step].component, {
                    values,
                    setFieldValue,
                    errors,
                    displayErrors,
                  })}
                  <FloatingControls
                    handlePrev={handlePrev}
                    handleNext={() => handleNext(validateForm)}
                    isFirstStep={isFirstStep}
                    isLastStep={isLastStep}
                    displayErrors={() => setDisplayErrors(true)}
                    isLoading={isLoading}
                    handlePreview={openPreview}
                    editMode={editMode}
                  />
                </Form>
              );
            }}
          </Formik>
          <Preview
            isOpen={previewIsOpen}
            onClose={closePreview}
            campaign={previewObject}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default Wizard;
