//React imports
import React from "react"
import Checkbox from "@mui/material/Checkbox"
//Custom styles
import "./styles.scss"
//Assets
import { CheckboxCheckedIcon, CheckboxUncheckedIcon } from "../../../../../../assets/images/Common"

const CheckboxButton = ({ checked, disabled }) => {
  const customStyles = {
    color: "#BDBDBD",
    "&.Mui-checked": {
      color: "#94A4FF",
    },
    "& .MuiSvgIcon-root": {
      fontSize: 26,
    },
  }

  return (
    <div className="campaignFormCheckbox">
      <Checkbox icon={<img src={CheckboxUncheckedIcon} alt="icon" />} checkedIcon={<img src={CheckboxCheckedIcon} alt="icon" />} sx={customStyles} checked={checked} disabled={disabled} />
    </div>
  )
}

export default CheckboxButton
