//React imports
import React from "react"
import { useDropzone } from "react-dropzone"
//Custom styles
import "./styles.scss"
//Assets
import { UploadIcon } from "../../../../../../assets/images/Campaign"

const ImageDropzone = ({ setFiles }) => {
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      "image/jpeg": [],
      "image/jpg": [],
      "image/png": [],
    },
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles)
    },
  })

  return (
    <div className="campaignFormImageDropzone" {...getRootProps()}>
      <input type="file" className="fileInput" {...getInputProps()} multiple={false} />
      <img src={UploadIcon} alt="upload" className="icon" />
    </div>
  )
}

export default ImageDropzone
