import React, { useState, useEffect, useRef } from "react"
import BookmarkModal from "../../../components/Common/BookmarkModal"
import Pagination from "../../../components/Common/Pagination"
import { sortingOptions, saveFilterAllowedAccounts } from "../../../constants"
import InfluencerCard from "../../../components/FindInfluencers/Card"
import FiltersContainer from "../../../components/FindInfluencers/FiltersContainer"
import useInfluencerService from "../../../services/useInfluencerService"
import useBoardsService from "../../../services/useBoardsService"
import Skeleton from "@mui/material/Skeleton"
import PlatformSwitch from "../../../components/FindInfluencers/PlatformSwitch"
import { Button } from "../../../components/Common/CommonElements"
import { useSelector } from "react-redux"
import NoFiltersView from "../../../components/FindInfluencers/NoFiltersView"
import EmptyView from "../../../components/FindInfluencers/EmptyView"
import SelectCards from "../../../components/FindInfluencers/SelectCards"
import SearchBar from "../../../components/FindInfluencers/SearchBar"
import SortCards from "../../../components/FindInfluencers/SortCards"
import SelectedFilters from "../../../components/FindInfluencers/SelectedFilters"
import { humanizeNumber, isArrayEmpty, isObjectEmpty } from "../../../helpers"
import { useSearchParams } from "react-router-dom"
import notify from "../../../components/Common/Toast"
import { FormModal } from "../../../components/Common/Modals"
import Playbook from "../../../components/FindInfluencers/Playbook"
import { useNavigate } from "react-router-dom"
import { useInfluencerActions } from "../../../redux/hooks/useInfluencerActions"
import "./styles.scss"

const FindInfluencers = ({ openDetails }) => {
  const influencerService = useInfluencerService()
  const influencerActions = useInfluencerActions()
  const boardsService = useBoardsService()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const isFirstRender = useRef(true)
  const bookmarkModalRef = useRef()
  const bookmarkModal = bookmarkModalRef.current

  const { user, credits } = useSelector((state) => state.auth)
  const { influencers, currentPlatform, currentFilters, appliedFilters, currentPage, currentSorting, totalItems, totalResults } = useSelector((state) => state.influencers)
  const { setInfluencers, setCurrentFilters, setAppliedFilters, setCurrentPage, setCurrentPlatform, setCurrentSorting, setTotalItems, setTotalResults } = influencerActions

  const [influencerFilters, setInfluencerFilters] = useState(currentFilters?.influencer || {})
  const [audienceFilters, setAudienceFilters] = useState(currentFilters?.audience || {})
  const [selectedCards, setSelectedCards] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [currentPlaybook, setCurrentPlaybook] = useState()

  useEffect(() => {
    !influencers && fetchInfluencers()
  }, [])

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    fetchInfluencers(currentPlatform, currentPage, appliedFilters, currentSorting)
  }, [currentPlatform, currentPage, appliedFilters, currentSorting])

  useEffect(() => {
    const platform = searchParams.get("platform")
    const filters = JSON.parse(searchParams.get("filters"))
    const page = searchParams.get("page")
    const sorting = sortingOptions.find((option) => option.value === searchParams.get("sort"))

    if (filters) {
      setInfluencerFilters(filters?.influencer || {})
      setAudienceFilters(filters?.audience || {})
      setCurrentFilters(filters)
    }

    if (!influencers || platform || filters || page || sorting) {
      const parsedFilters = parseFilters(filters?.influencer, filters?.audience)
      fetchInfluencers(platform || currentPlatform, page || currentPage, parsedFilters || appliedFilters, sorting || currentSorting)
    }
  }, [])

  const fetchInfluencers = (platform, page, filters, sorting) => {
    setIsLoading(true)
    influencerService
      .index(platform, page - 1, filters, sorting)
      .then((data) => {
        if (data) {
          setInfluencers(data.influencers)
          setTotalItems(data.meta.pagination.total_items)
          setTotalResults(humanizeNumber(data.meta.total_results))
        }
      })
      .finally(() => {
        setIsLoading(false)
        setSearchParams("")
      })
  }

  const [formModal, setFormModal] = useState({
    isOpen: false,
    isLoading: false,
  })

  const openFormModal = () => {
    setFormModal((prevState) => ({
      ...prevState,
      isOpen: true,
    }))
  }

  const closeFormModal = () => {
    setFormModal((prevState) => ({
      ...prevState,
      isOpen: false,
    }))
  }

  const setFormModalLoading = (value) => {
    setFormModal((prevState) => ({
      ...prevState,
      isLoading: value,
    }))
  }

  const filtersAreEmpty = isObjectEmpty(influencerFilters) && isObjectEmpty(audienceFilters)

  const handleCardClick = (influencer) => {
    if (influencer.saved !== false || credits >= 5) {
      openDetails(influencer.id)
    } else {
      notify.error("Not enough credits to perform this action, please upgrade your plan")
      navigate("/brand/plans")
    }
  }

  const onPlatformChange = (platform) => {
    setCurrentPlatform(platform)
    setCurrentPage(1)
    setCurrentFilters({})
    setAppliedFilters({})
    setInfluencerFilters({})
    setAudienceFilters({})
  }

  const onPageChange = (page) => {
    if (credits >= 1) {
      if (page + 1 !== currentPage) {
        setCurrentPage(page + 1)
      }
      document.getElementById("cards-container").scrollIntoView({ behavior: "smooth" })
    } else {
      notify.error("Not enough credits to perform this action, please upgrade your plan")
      navigate("/brand/plans")
    }
  }

  const submitFilters = () => {
    if (credits >= 1) {
      const parsedFilters = parseFilters(influencerFilters, audienceFilters)
      setCurrentPage(1)
      setAppliedFilters({
        influencer: parsedFilters.influencer,
        audience: parsedFilters.audience,
      })
      setCurrentFilters({
        influencer: influencerFilters,
        audience: audienceFilters,
      })
    } else {
      notify.error("Not enough credits to perform this action, please upgrade your plan")
      navigate("/brand/plans")
    }
  }

  const clearFilters = () => {
    setInfluencerFilters({})
    setAudienceFilters({})
    setCurrentPage(1)
    if (!isObjectEmpty(appliedFilters)) {
      setAppliedFilters({})
    }
    setCurrentFilters({})
    setCurrentPlaybook()
  }

  const copyAndSaveFilters = ({ name }) => {
    const url = generateUrl()
    navigator.clipboard.writeText(url)

    setFormModalLoading(true)
    influencerService
      .saveFilters(name, url)
      .then((data) => {
        if (data) {
          closeFormModal()
        }
      })
      .finally(() => {
        setFormModalLoading(false)
      })

    return true
  }

  const generateUrl = () => {
    const encodedFilters = encodeURIComponent(JSON.stringify({ influencer: influencerFilters, audience: audienceFilters }))
    const { protocol, hostname, port } = window.location
    return `${protocol}//${hostname}${port ? `:${port}` : ""}/brand/find-influencers?platform=${currentPlatform}&page=${currentPage}&sort=${currentSorting["value"]}&filters=${encodedFilters}`
  }

  const parseFilters = (influencerFilter, audienceFilter) => {
    const influencer = {}
    const audience = {}

    !isObjectEmpty(influencerFilter) &&
      Object.entries(influencerFilter).forEach(([key, value]) => {
        if (value !== null && value?.length !== 0) {
          switch (key) {
            case "accountTypes":
            case "location":
            case "brands":
            case "interests":
              influencer[key] = value.map((item) => item.value)
              break
            case "age":
            case "followers":
            case "bio":
            case "keywords":
            case "reelsPlays":
            case "views":
            case "engagements":
            case "saves":
            case "shares":
              influencer[key] = value
              break
            case "engagementRate":
            case "gender":
            case "language":
            case "lastposted":
            case "hasSponsoredPosts":
            case "isVerified":
            case "hasYouTube":
              influencer[key] = value.value
              break
            case "followersGrowthRate":
            case "likesGrowthRate":
            case "viewsGrowthRate":
              influencer[key] = { interval: value.value, value: value.weight, operator: value.weight > 0 ? "gt" : "lt" }
              break
            case "hasContactDetails":
              influencer[key] = value.map((item) => ({ contactType: item.value, filterAction: "should" }))
              break
            case "hashtags":
              influencer["textTags"] = (influencer["textTags"] || []).concat(value.map((item) => ({ type: "hashtag", value: item.value.trim() })))
              break
            case "mentions":
              influencer["textTags"] = (influencer["textTags"] || []).concat(value.map((item) => ({ type: "mention", value: item.value })))
              break
            case "topics":
              influencer["relevance"] = (influencer["relevance"] || []).concat(
                value.map((item) => {
                  const trimmedValue = item.value.trim()
                  return trimmedValue.startsWith("#") ? trimmedValue : `#${trimmedValue}`
                })
              )
              break
            case "lookalikes":
              influencer["relevance"] = (influencer["relevance"] || []).concat(
                value.map((item) => {
                  const trimmedValue = item.value.trim()
                  return trimmedValue.startsWith("@") ? trimmedValue : `@${trimmedValue}`
                })
              )
              break
            default:
              break
          }
        }
      })

    !isObjectEmpty(audienceFilter) &&
      Object.entries(audienceFilter).forEach(([key, value]) => {
        if (value !== null && value?.length !== 0) {
          switch (key) {
            case "credibility":
              audience[key] = value.value
              break
            case "gender":
            case "language":
              audience[key] = { id: value.value, weight: value?.weight }
              break
            case "interests":
            case "location":
            case "age":
              audience[key] = value.map((item) => ({ id: item.value, weight: item?.weight }))
              break
            default:
              break
          }
        }
      })

    return { influencer, audience }
  }

  const handleCardSelect = (id) => {
    if (!selectedCards?.includes(id)) {
      setSelectedCards((prev) => [...prev, id])
    } else {
      setSelectedCards((prev) => prev.filter((influencer_id) => influencer_id !== id))
    }
  }

  const handleCardBulkSelect = () => {
    if (!influencers?.map((influencer) => influencer.id).every((id) => selectedCards.includes(id))) {
      setSelectedCards(influencers?.map((influencer) => influencer.id))
    } else {
      setSelectedCards([])
    }
  }

  const handleAddCardsToBoard = (influencers) => {
    bookmarkModal?.openModal(influencers, addCardsToBoard)
  }

  const addCardsToBoard = (values) => {
    bookmarkModal.setLoading(true)
    boardsService.createCard(values).then((data) => {
      if (data) {
        setSelectedCards([])
        bookmarkModal.setLoading(false)
        bookmarkModal.closeModal()
      }
    })
  }

  return (
    <div id="find-influencers-section">
      <div className="control-panel-container">
        <div className="actions-container">
          <PlatformSwitch currentPlatform={currentPlatform} onPlatformChange={onPlatformChange} />
          <SearchBar name="lookalikes" service={influencerService.searchInfluencers} platform={currentPlatform} limit={15} state={influencerFilters} setState={setInfluencerFilters} />
        </div>
        <FiltersContainer
          currentPlatform={currentPlatform}
          currentFilters={appliedFilters}
          influencerFilters={influencerFilters}
          setInfluencerFilters={setInfluencerFilters}
          audienceFilters={audienceFilters}
          setAudienceFilters={setAudienceFilters}
        />
        <div className="flex-container">
          {isLoading ? (
            <Skeleton variant="rounded" width={150} height={24} />
          ) : totalResults ? (
            <div className="total-results-text">{totalResults} results</div>
          ) : (
            <div className="total-results-text">No results found</div>
          )}
          <div className="flex-container">
            {!filtersAreEmpty && saveFilterAllowedAccounts.includes(user?.email) && (
              <Button
                type="button"
                label="Copy Filters"
                onClick={openFormModal}
                theme={{ backgroundColor: "#ffffff", borderColor: "#000000", color: "black", padding: "0.8rem 2.2rem", fontSize: "14px" }}
              />
            )}
            {(!filtersAreEmpty || !isObjectEmpty(currentFilters)) && (
              <Button
                type="button"
                label="Clear All"
                onClick={clearFilters}
                theme={{ backgroundColor: "#ffffff", borderColor: "#000000", color: "black", padding: "0.8rem 2.2rem", fontSize: "14px" }}
              />
            )}
            <Button
              type="button"
              label="Apply Filters"
              onClick={submitFilters}
              theme={{ backgroundColor: "#000000", color: "#ffffff", padding: "0.8rem 2.2rem", fontSize: "14px" }}
              isDisabled={filtersAreEmpty}
            />
          </div>
        </div>
      </div>
      <div id="cards-container"></div>
      <SelectedFilters
        isLoading={isLoading}
        influencerFilters={influencerFilters}
        setInfluencerFilters={setInfluencerFilters}
        audienceFilters={audienceFilters}
        setAudienceFilters={setAudienceFilters}
        currentPlaybook={currentPlaybook}
      />
      <div className="actions-flex-container">
        <SelectCards influencers={influencers} isLoading={isLoading} selectedCards={selectedCards} handleCardBulkSelect={handleCardBulkSelect} handleAddCardsToBoard={handleAddCardsToBoard} />
        <SortCards isLoading={isLoading} state={currentSorting} setState={setCurrentSorting} cardsPresent={!isArrayEmpty(influencers)} filtersApplied={!isObjectEmpty(appliedFilters)} />
      </div>
      <div className="cards-container">
        {isLoading
          ? Array.from({ length: 15 }, (_, index) => <Skeleton key={index} className="card-skeleton" variant="rounded" height={277} />)
          : influencers?.map((influencer, index) => (
              <InfluencerCard
                key={influencer.id}
                influencer={influencer}
                handleAddCardsToBoard={handleAddCardsToBoard}
                handleCardSelect={handleCardSelect}
                handleCardClick={handleCardClick}
                selectedCards={selectedCards}
                setSelectedCards={setSelectedCards}
                lockFeatures={user?.lock_features && index !== 0}
              />
            ))}
      </div>
      {!isLoading &&
        influencers?.length > 0 &&
        (!isObjectEmpty(appliedFilters) ? <Pagination activePage={currentPage} totalItems={totalItems} onPageChange={onPageChange} lockFeatures={user?.lock_features} /> : <NoFiltersView />)}
      {!isLoading && influencers?.length === 0 && <EmptyView />}
      <BookmarkModal ref={bookmarkModalRef} />
      <FormModal isOpen={formModal.isOpen} closeModal={closeFormModal} isLoading={formModal.isLoading} heading="Enter a name for your filters" callback={copyAndSaveFilters} />
      <Playbook
        username={user?.name}
        setCurrentPlatform={setCurrentPlatform}
        influencerService={influencerService}
        influencerFilters={influencerFilters}
        setInfluencerFilters={setInfluencerFilters}
        audienceFilters={audienceFilters}
        setAudienceFilters={setAudienceFilters}
        setCurrentPlaybook={setCurrentPlaybook}
        submitFilters={submitFilters}
        clearFilters={clearFilters}
      />
    </div>
  )
}

export default FindInfluencers
