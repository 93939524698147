// React import
import React, { useState } from "react";

// Custom Component
import ProviderCard from "../../Mailboxes/Authentication/ProviderCard";

//Component to render
const MailBoxes = () => {
  return (
    <div>
      <div className="tab-pane w-650 ">
        <div className="d-flex flex-column">
          <span className="fs-5 fw-bold">Mailboxes</span>

          <span className="mt-3 font-13 fw-light">
            Connect or Disconnect your account.
          </span>
        </div>
        <div className="d-flex justify-content-between mt-4">
          <ProviderCard provider={"google"} border={"solid"} />
          <ProviderCard provider={"microsoft"} border={"solid"} />
        </div>
      </div>
    </div>
  );
};

export default MailBoxes;
