import React, { useState, useEffect, useRef } from "react"
import Header from "../../../components/Common/Header"
import InfluencerCard from "../../../components/FindInfluencers/Card"
import useInfluencerService from "../../../services/useInfluencerService"
import Pagination from "../../../components/Common/Pagination"
import useBoardsService from "../../../services/useBoardsService"
import BookmarkModal from "../../../components/Common/BookmarkModal"
import { platforms } from "../../../constants"
import Skeleton from "@mui/material/Skeleton"
import PlatformSwitch from "../../../components/FindInfluencers/PlatformSwitch"
import { LoadingOverlay } from "../../../components/Common/Loaders"
import SelectCards from "../../../components/FindInfluencers/SelectCards"
import DefaultView from "../../../components/ManageInfluencers/DefaultView"
import EmptyView from "../../../components/FindInfluencers/EmptyView"
import { BoardsFilter, SearchBar } from "../../../components/ManageInfluencers/Filters"
import "./styles.scss"

const ManageInfluencers = ({ openDetails }) => {
  const influencerService = useInfluencerService()
  const boardsService = useBoardsService()

  const bookmarkModalRef = useRef()
  const bookmarkModal = bookmarkModalRef.current

  const [savedInfluencers, setSavedInfluencers] = useState([])
  const [currentPlatform, setCurrentPlatform] = useState(platforms[0])
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(0)
  const [totalItems, setTotalItems] = useState(0)
  const [selectedCards, setSelectedCards] = useState([])
  const [boards, setBoards] = useState()
  const [filters, setFilters] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    fetchSavedInfluencers()
  }, [currentPlatform, currentPage, filters])

  useEffect(() => {
    setCurrentPage(1)
  }, [filters])

  useEffect(() => {
    boardsService.index().then((data) => {
      if (data) {
        setBoards(data)
      }
    })
  }, [])

  const fetchSavedInfluencers = () => {
    setIsLoading(true)
    influencerService
      .savedInfluencers(currentPlatform, currentPage, filters)
      .then((data) => {
        if (data) {
          setSavedInfluencers(data.influencers)
          setCurrentPage(data.meta.pagination.page)
          setTotalItems(data.meta.pagination.count)
          setItemsPerPage(data.meta.pagination.items)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleCardClick = (influencer) => {
    openDetails(influencer.id)
  }

  const onPlatformChange = (platform) => {
    setCurrentPlatform(platform)
    setCurrentPage(1)
  }

  const onPageChange = (page) => {
    if (page + 1 !== currentPage) {
      setCurrentPage(page + 1)
    }
    document.getElementById("cards-container").scrollIntoView({ behavior: "smooth" })
  }

  const removeInfluencer = (id) => {
    influencerService.removeSavedInfluencer(id).then((data) => {
      if (data) {
        if (savedInfluencers.length === 1 && currentPage > 1) {
          setCurrentPage(currentPage - 1)
        }
      }
    })
  }

  const handleCardSelect = (id) => {
    if (!selectedCards?.includes(id)) {
      setSelectedCards((prev) => [...prev, id])
    } else {
      setSelectedCards((prev) => prev.filter((influencer_id) => influencer_id !== id))
    }
  }

  const handleCardBulkSelect = () => {
    if (!savedInfluencers?.map((influencer) => influencer.id).every((id) => selectedCards.includes(id))) {
      setSelectedCards(savedInfluencers?.map((influencer) => influencer.id))
    } else {
      setSelectedCards([])
    }
  }

  const handleAddCardsToBoard = (influencers) => {
    bookmarkModal?.openModal(influencers, addCardsToBoard)
  }

  const addCardsToBoard = (values) => {
    bookmarkModal.setLoading(true)
    boardsService.createCard(values).then((data) => {
      if (data) {
        setSelectedCards([])
        bookmarkModal.setLoading(false)
        bookmarkModal.closeModal()
      }
    })
  }

  const filtersAreEmpty = Object.values(filters).every((value) => value === null || value === "" || (Array.isArray(value) && value.length === 0))

  return (
    <div id="manage-influencers-section">
      <div className="control-panel-container">
        <div className="actions-container">
          <PlatformSwitch currentPlatform={currentPlatform} onPlatformChange={onPlatformChange} />
          <SearchBar name="query" state={filters} setState={setFilters} />
          <BoardsFilter label="Filter By Boards" name="boards" boards={boards} state={filters} setState={setFilters} />
        </div>
      </div>
      <div id="cards-container"></div>
      <SelectCards influencers={savedInfluencers} isLoading={isLoading} selectedCards={selectedCards} handleCardBulkSelect={handleCardBulkSelect} bookmarkModalRef={bookmarkModalRef} />
      <div className="cards-container">
        {isLoading
          ? Array.from({ length: 15 }, (_, index) => <Skeleton key={index} className="card-skeleton" variant="rounded" height={277} />)
          : savedInfluencers?.map((influencer) => (
              <InfluencerCard
                key={influencer.id}
                influencer={influencer}
                showBoards={true}
                handleCardClick={handleCardClick}
                handleAddCardsToBoard={handleAddCardsToBoard}
                handleCardSelect={handleCardSelect}
                selectedCards={selectedCards}
                setSelectedCards={setSelectedCards}
                removeInfluencer={removeInfluencer}
              />
            ))}
      </div>
      {!isLoading && savedInfluencers?.length > 0 && <Pagination activePage={currentPage} totalItems={totalItems} itemsPerPage={itemsPerPage} onPageChange={onPageChange} />}
      {!isLoading && savedInfluencers?.length === 0 && !filtersAreEmpty && <EmptyView />}
      {!isLoading && savedInfluencers?.length === 0 && filtersAreEmpty && <DefaultView />}
      <BookmarkModal ref={bookmarkModalRef} callback={addCardsToBoard} />
      <LoadingOverlay state={isLoading} />
    </div>
  )
}

export default ManageInfluencers
