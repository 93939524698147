import React, { useState, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import { Box } from "@mui/material";

import Form from "react-bootstrap/Form";

// custom components
import { Button } from "../../../Common/CommonElements";
import RichTextEditor from "../../RichTextEditor";

// style
import "./style.scss";
// Services
import useEmailTemplatesService from "../../../../services/useEmailTemplatesService";

const TemplateCompose = ({
  setOpenTemplateModal,
  templateId,
  setEmailTemplates,
}) => {
  // Service Constants
  const emailTemplateService = useEmailTemplatesService();

  // Use States
  const [templateData, setTemplateData] = useState({
    name: "",
    subject: "",
    content: "",
  });

  const [templateDataLoaded, setTemplateDataLoaded] = useState(false);

  // Use Effects
  useEffect(() => {
    if (templateId) {
      emailTemplateService.template(templateId).then((data) => {
        if (data) {
          setTemplateData({ ...templateData, ...data });
          setTemplateDataLoaded(true);
        }
      });
    } else {
      setTemplateDataLoaded(true);
    }
  }, []);

  // Handlers
  const handleChange = (e) => {
    const { name, value } = e.target;
    setTemplateData({ ...templateData, [name]: value });
  };

  const setContentData = (parsedContent) => {
    setTemplateData({ ...templateData, ["content"]: parsedContent });
  };

  const showLoaderAndPreventDefault = (e) => {
    e.preventDefault();
    document
      .getElementById("templateProcessinggSpinner")
      .classList.remove("d-none");
  };

  const handleCreateTemplate = (e) => {
    showLoaderAndPreventDefault(e);
    emailTemplateService
      .create(templateData)
      .then((result) => {
        if (result) {
          setEmailTemplates(result);
          setOpenTemplateModal(false);
        }
      })
      .catch((error) => {
        console.log(error.response.data.error);
      });
  };

  const handleUpdateTemplate = (e) => {
    showLoaderAndPreventDefault(e);
    emailTemplateService
      .update(templateId, templateData)
      .then((result) => {
        if (result) {
          setEmailTemplates(result);
          setOpenTemplateModal(false);
        }
      })
      .catch((error) => {
        console.log(error.response.data.error);
      });
  };

  return (
    <Box>
      <form>
        <Box sx={{ marginBottom: "20px", marginTop: "10px" }}>
          <Form.Group controlId="emailInput">
            <Form.Label className="header-font-14">Template Name</Form.Label>
            <Form.Control
              type="text"
              defaultValue={templateData.name}
              className="template-input"
              name="name"
              onChange={handleChange}
              placeholder={"Template Name"}
            />
          </Form.Group>
        </Box>

        <Box sx={{ marginBottom: "10px", marginTop: "30px" }}>
          <Form.Control
            type="text"
            className="template-input"
            name="subject"
            value={templateData.subject}
            onChange={handleChange}
            placeholder="Subject"
            required
          />
        </Box>

        <Box sx={{ marginBottom: "20px" }}>
          {templateDataLoaded && (
            <RichTextEditor
              content={templateData.content}
              setContentData={setContentData}
            />
          )}
        </Box>
        <div className="d-flex justify-content-end position-relative gap-3">
          <Button
            label="Cancel"
            onClick={() => {
              setOpenTemplateModal(false);
            }}
            theme={{
              backgroundColor: "#f6f7fb",
              color: "#000",
              padding: "0.5rem 2.2rem",
              fontSize: "14px",
            }}
          />
          <Spinner
            animation="border"
            variant="light"
            size="sm"
            className={`template-loading-spinner-position d-none ${
              templateId ? "" : "right-130"
            }`}
            id="templateProcessinggSpinner"
          />
          <Button
            type="submit"
            label={templateId ? "Update Template" : "Save Template"}
            onClick={templateId ? handleUpdateTemplate : handleCreateTemplate}
            theme={{
              backgroundColor: "#94A3FF",
              color: "#ffffff",
              padding: "0.5rem 1.6rem",
              fontSize: "14px",
            }}
          />
        </div>
      </form>
    </Box>
  );
};

export default TemplateCompose;
