import useBaseService from "./useBaseService";
import notify from "../components/Common/Toast";
import { useNavigate } from "react-router-dom";

const useCampaignService = () => {
  const axiosRequest = useBaseService().axiosRequest();
  const navigate = useNavigate();

  const list = async (searchQuery, page, status = "") => {
    try {
      const response = await axiosRequest.get(
        `/campaigns?page=${page}&query=${searchQuery}&filtered_status=${status}`
      );
      return response.data;
    } catch (error) {
      notify.error(error.response.data.error);
      return false;
    }
  };

  const publicVisibleCampaign = async (campaignSlug) => {
    try {
      const response = await axiosRequest.get(
        `/public_actions/campaigns/${campaignSlug}`
      );
      return response.data;
    } catch (error) {
      notify.error(error.response.data.error);
      return false;
    }
  };

  const show = async (campaignSlug) => {
    try {
      const response = await axiosRequest.get(`/campaigns/${campaignSlug}`);
      return response.data;
    } catch (error) {
      notify.error(error.response.data.error);
      navigate("/brand/campaigns");
      return false;
    }
  };

  const remove = async (campaignId, searchQuery) => {
    try {
      const response = await axiosRequest.delete(
        `/campaigns/${campaignId}?query=${searchQuery}`
      );
      notify.success("Campaign deleted successfully.");
      return response.data;
    } catch (error) {
      notify.error(error.response.data.error);
      return false;
    }
  };

  const changeStatus = async (campaignId, status) => {
    try {
      const response = await axiosRequest.put(
        `/campaigns/${campaignId}/change_status`,
        {
          status: status,
        }
      );
      notify.success(
        `Campaign ${response.data.title} is ${response.data.status} successfully.`
      );
      return response.data;
    } catch (error) {
      notify.error(error.response.data.error);
      return false;
    }
  };

  const create = async (object) => {
    try {
      await axiosRequest.post("campaigns", object, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      notify.success("Campaign was created successfully !");
      return true;
    } catch (error) {
      notify.error(error.response.data.error);
      return false;
    }
  };

  const edit = async (object, id) => {
    try {
      const response = await axiosRequest.patch(`campaigns/${id}`, object, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      notify.success("Campaign was updated successfully !");
      return response.data.campaign;
    } catch (error) {
      notify.error(error.response.data.error);
      return false;
    }
  };

  const categories = async () => {
    try {
      const response = await axiosRequest.get("campaigns/categories");
      return response.data;
    } catch (error) {
      notify.error("There was an error fetching the form data");
      return false;
    }
  };

  const locations = async (query) => {
    try {
      const response = await axiosRequest.get("campaigns/locations", {
        params: {
          query,
        },
      });
      return response.data;
    } catch (error) {
      notify.error("There was an error fetching the form data");
      return false;
    }
  };

  return {
    list,
    remove,
    changeStatus,
    publicVisibleCampaign,
    create,
    edit,
    categories,
    locations,
    show,
  };
};

export default useCampaignService;
