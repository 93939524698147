//React imports
import React, { useState, useEffect } from "react"
import Skeleton from "@mui/material/Skeleton"
// Custom component imports
import CategoryCard from "../Common/CategoryCard"
import OptionCard from "../Common/OptionCard"
import CategoryListing from "../Common/CategoryListing"
import LocationSearch from "../Common/LocationSearch"
import LocationCard from "../Common/LocationCard"
// Custom services
import useCampaignService from "../../../../../services/useCampaignService"
// Custom styles
import "./styles.scss"
// Assets
import { LocationIcon, GlobeIcon, LoadCategoriesIcon } from "../../../../../assets/images/Campaign"

const Locations = ({ values: { category, locations }, setFieldValue, errors, displayErrors }) => {
  const [isLoading, setLoading] = useState(false)
  const [categories, setCategories] = useState()
  const [categoryListingIsOpen, setCategoryListingOpen] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState(category)
  const [prevLocations, setPrevLocations] = useState(locations)

  const campaignService = useCampaignService()

  useEffect(() => {
    setLoading(true)
    campaignService
      .categories()
      .then((data) => {
        if (data) {
          setCategories(data)
          category && sortCategories(data)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const setCategory = (value) => {
    setFieldValue("category", value)
    setSelectedCategory(value)
  }

  const setLocationType = (type) => {
    if (type === "worldwide") {
      setPrevLocations(locations)
      setFieldValue("locations", null)
    } else if (type === "locations") {
      setFieldValue("locations", prevLocations)
    }
  }

  const addLocation = (option) => {
    const index = locations.findIndex((location) => location.name === option.value.name)

    if (index !== -1) {
      if (locations[index]?._destroy) {
        const updatedLocations = [...locations]
        updatedLocations[index] = {
          ...updatedLocations[index],
          _destroy: undefined,
        }
        setFieldValue("locations", updatedLocations)
      } else {
        setFieldValue("locations", locations)
      }
    } else {
      setFieldValue("locations", [...locations, { ...option.value }])
    }
  }

  const removeLocation = (location) => {
    if (location.new) {
      setFieldValue(
        "locations",
        locations.filter((loc) => loc.id !== location.id)
      )
    } else {
      setFieldValue(
        "locations",
        locations.map((loc) => (loc.name === location.name ? { ...loc, _destroy: true } : loc))
      )
    }
  }

  const openCategoryListing = () => {
    setCategoryListingOpen(true)
  }

  const closeCategoryListing = () => {
    setCategoryListingOpen(false)
    selectedCategory && sortCategories(categories)
  }

  const sortCategories = (data) => {
    const sortedCategories = data.sort((a, b) => (a.id === category.id ? -1 : b.id === category.id ? 1 : 0))
    setCategories([...sortedCategories])
  }

  return (
    <div className="campaignFormLocationsStep">
      <div className="categorySelectionContainer">
        <div className="heading">Choose the category that best describes your offer</div>
        {displayErrors && errors?.category && <div className="errorMessage">{errors.category}</div>}
        <div className="categoryCardsContainer">
          {isLoading ? (
            Array.from({ length: 7 }, (_, index) => <Skeleton key={index} className="categorySkeleton" variant="rounded" width={165} height={208} />)
          ) : (
            <>
              {categories?.slice(0, 6)?.map((object, index) => (
                <CategoryCard key={index} label={object.name} image={object.thumbnail} selected={category?.id === object.id} onClick={() => setCategory(object)} />
              ))}
              <div className="loadCategoriesCard" onClick={openCategoryListing}>
                <img src={LoadCategoriesIcon} alt="load" className="icon" />
              </div>
            </>
          )}
        </div>
      </div>
      <div className="locationSelectionContainer">
        <div className="heading">What is your offer location?</div>
        {displayErrors && errors?.locations && <div className="errorMessage">{errors.locations}</div>}
        <div className="locationTypeSelectionContainer">
          <OptionCard title="Specific Locations" icon={LocationIcon} type="radio" selected={Array.isArray(locations)} onClick={() => setLocationType("locations")} />
          <OptionCard title="Worldwide" icon={GlobeIcon} type="radio" selected={locations === null} onClick={() => setLocationType("worldwide")} />
        </div>
        {locations && <LocationSearch handleChange={addLocation} />}
        {locations && (
          <div className="selectedLocations">
            {locations
              .filter((location) => !location._destroy)
              .map((location, index) => (
                <LocationCard key={index} location={location} handleRemove={() => removeLocation(location)} />
              ))}
          </div>
        )}
      </div>
      {categories && <CategoryListing categories={categories} setCategory={setCategory} selectedCategory={category} isOpen={categoryListingIsOpen} onClose={closeCategoryListing} />}
    </div>
  )
}

export default Locations
