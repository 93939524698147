import useBaseService from "./useBaseService";
import notify from "../components/Common/Toast";

const useEmailTemplatesService = () => {
  const axiosRequest = useBaseService().axiosRequest();

  const list = async (query) => {
    try {
      const response = await axiosRequest.get(
        `/email_templates?query=${query}`
      );
      return response.data;
    } catch (error) {
      notify.error(error.response.data.error);
      return false;
    }
  };

  const create = async (templateData) => {
    try {
      const response = await axiosRequest.post(`/email_templates`, {
        template_data: templateData,
      });
      notify.success("Template created successfully.");
      return response.data;
    } catch (error) {
      document
        .getElementById("templateProcessinggSpinner")
        .classList.add("d-none");
      notify.error(error.response.data.error);
      return false;
    }
  };

  const template = async (templateId) => {
    try {
      const response = await axiosRequest.get(`/email_templates/${templateId}`);
      return response.data;
    } catch (error) {
      notify.error(error.response.data.error);
      return false;
    }
  };

  const remove = async (templateId, searchQuery) => {
    try {
      const response = await axiosRequest.delete(
        `/email_templates/${templateId}?query=${searchQuery}`
      );
      notify.success("Email template deleted successfully.");
      return response.data;
    } catch (error) {
      notify.error(error.response.data.error);
      return false;
    }
  };

  const update = async (templateId, templateData) => {
    try {
      const response = await axiosRequest.put(
        `/email_templates/${templateId}`,
        {
          template_data: templateData,
        }
      );
      notify.success("Template updated successfully.");
      return response.data;
    } catch (error) {
      document
        .getElementById("templateProcessinggSpinner")
        .classList.add("d-none");
      notify.error(error.response.data.error);
      return false;
    }
  };

  return {
    list,
    create,
    template,
    remove,
    update,
  };
};

export default useEmailTemplatesService;
