// React import
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

//custom component render
import { Button } from "../../Common/CommonElements";
import ConnectModal from "../Authentication/ConnectModal";
import EmailModal from "../EmailModal";

// Component to render
const SendEmailButton = ({ email, name, influencerId }) => {
  // redux constants
  const currentUser = useSelector((state) => state.auth?.user);

  // helper constants
  const sentToDetails = {
    email: email,
    name: name,
    influencerId: influencerId,
  };
  const connectedProvidersList = currentUser.connected_mailbox_grants || [];

  // use states
  const [openConnectModal, setOpenConnectModal] = useState(false);

  const handleOpenBoxModal = () => {
    setOpenConnectModal(true);
  };

  // Html
  return (
    <>
      <Button
        type="button"
        label="Send Email"
        onClick={handleOpenBoxModal}
        theme={{
          backgroundColor: "#27AE60",
          color: "#ffffff",
          padding: "0.3rem 1rem",
          fontSize: "12px",
        }}
      />
      {openConnectModal && connectedProvidersList.length == 0 && (
        <ConnectModal
          openConnectModal={openConnectModal}
          setOpenConnectModal={setOpenConnectModal}
        />
      )}

      {openConnectModal && connectedProvidersList.length > 0 && (
        <EmailModal
          openConnectModal={openConnectModal}
          setOpenConnectModal={setOpenConnectModal}
          sentToDetails={sentToDetails}
        />
      )}
    </>
  );
};

export default SendEmailButton;
