import React, { useState } from "react"
import Header from "../../../components/Common/Header"
import InfluencerInsights from "../InfluencerInsights"

const BrandDashboard = ({ children }) => {
  const [openDetails, setOpenDetails] = useState()

  return (
    <>
      <Header />
      {React.Children.map(children, (child) => React.cloneElement(child, { openDetails: setOpenDetails }))}
      <InfluencerInsights influencerId={openDetails} setInfluencerId={setOpenDetails} />
    </>
  )
}

export default BrandDashboard
