import { useDispatch } from "react-redux"
import {
  updateInfluencers,
  updatePlatform,
  updateCurrentFilters,
  updateAppliedFilters,
  updateSorting,
  updatePage,
  updateTotalItems,
  updateTotalResults,
  resetState,
} from "../reducers/influencerReducer"

export const useInfluencerActions = () => {
  const dispatch = useDispatch()

  const setInfluencers = (payload) => {
    dispatch(updateInfluencers(payload))
  }

  const setCurrentPlatform = (payload) => {
    dispatch(updatePlatform(payload))
  }

  const setCurrentFilters = (payload) => {
    dispatch(updateCurrentFilters(payload))
  }

  const setAppliedFilters = (payload) => {
    dispatch(updateAppliedFilters(payload))
  }

  const setCurrentSorting = (payload) => {
    dispatch(updateSorting(payload))
  }

  const setCurrentPage = (payload) => {
    dispatch(updatePage(payload))
  }

  const setTotalItems = (payload) => {
    dispatch(updateTotalItems(payload))
  }

  const setTotalResults = (payload) => {
    dispatch(updateTotalResults(payload))
  }

  const resetInfluencerState = () => {
    dispatch(resetState())
  }

  return {
    setInfluencers,
    setCurrentPlatform,
    setCurrentFilters,
    setAppliedFilters,
    setCurrentSorting,
    setCurrentPage,
    setTotalItems,
    setTotalResults,
    resetInfluencerState,
  }
}
