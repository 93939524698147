//React imports
import React from "react"
import Slider from "@mui/material/Slider"
//Custom styles
import "./styles.scss"
//Custom helpers
import { humanizeNumber } from "../../../../../../helpers"

const RangeSlider = ({ min, max, steps, tooltipDisplay, legendDisplay, value, setValue }) => {
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const formatValue = (value) => {
    return humanizeNumber(value)
  }

  return (
    <div className="campaignFormRangeSlider">
      <Slider value={value} onChange={handleChange} step={steps} min={min} max={max} valueLabelFormat={formatValue} valueLabelDisplay={tooltipDisplay} />
      {legendDisplay && (
        <div className="legend">
          <div className="min">{humanizeNumber(min)}</div>
          <div className="max">{humanizeNumber(max)}</div>
        </div>
      )}
    </div>
  )
}

export default RangeSlider
