//React imports
import React from "react"
//Custom component imports
import CheckboxButton from "../CheckboxButton"
import RadioButton from "../RadioButton"
//Custom styles
import "./styles.scss"

const OptionCard = ({ title, description, icon, type, selected, onClick }) => {
  return (
    <div className={`campaignFormOptionCard ${selected ? "campaignFormOptionCardActive" : ""}`} onClick={onClick}>
      <div className="topContainer">
        <div className="icon-container">
          <img src={icon} alt="icon" className="icon" />
        </div>
        <div className="title">{title}</div>
        <div className="controlButton">{type === "checkbox" ? <CheckboxButton checked={selected} /> : type === "radio" ? <RadioButton checked={selected} /> : null}</div>
      </div>
      {description && <div className="description">{description}</div>}
    </div>
  )
}

export default OptionCard
